
import * as Tone from "tone";
import { ToneOscillatorType } from "tone";
import { Frequency } from "tone/build/esm/core/type/Units";

export default async function Beep(frequency: Frequency, type: ToneOscillatorType)
{
  return new Promise(resolve =>
  {
    const osc = new Tone.Oscillator(frequency, type).toDestination();
    osc.onstop = s =>
    {
      Tone.Transport.stop();
      s.dispose();
      resolve(undefined);
    };
    osc.sync().start(0).stop(0.05);
    Tone.Transport.start();
  });
}
