import { Avatar, Box, Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Container, Grid, IconButton, Theme, Typography, makeStyles } from "@material-ui/core";
import { green, red, yellow } from "@material-ui/core/colors";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import ClearIcon from '@material-ui/icons/Clear';
import FilterListIcon from '@material-ui/icons/FilterList';
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import React, { FunctionComponent, useState } from "react";
import { Link as RouterLink } from 'react-router-dom';
import { ListInventoryItem, useCheckPermissionQuery, useListInventoriesQuery } from "../../graphql/generated/graphql";
import PermissionKeys from "../../shared/PermissionKeys";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
  },
  card: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  inventoryAvatar: {
    color: theme.palette.action.active,
  },
  inventoryAvatarExecutable: {
    backgroundColor: yellow[600],
    color: theme.palette.getContrastText(yellow[600]),
  },
  inventoryAvatarExecuted: {
    backgroundColor: green[600],
    color: theme.palette.getContrastText(green[600]),
  },
  errorAvatar: {
    backgroundColor: red[500],
  },
  item: {
    marginRight: theme.spacing(1),
  },
  filters: {
    marginLeft: "auto",
    marginRight: "auto",
    paddingRight: theme.spacing(1),
    width: 48 * 2,
    marginBottom: theme.spacing(2),
  },
  progress: {
    marginLeft: "auto",
    marginRight: "auto",
    width: 40,
  },
}));

export default function Component()
{

  const noPermission = () =>
  {
    return <Container maxWidth="xs">
      <Card>
        <CardHeader title="Leltárak" subheader="Nincs jogosultság" avatar={<Avatar className={classes.errorAvatar}>X</Avatar>} />
        <CardContent>
          <Typography variant="body2" component="p">Nincs jogosultságod az oldal megtekintéséhez.</Typography>
        </CardContent>
        <CardActions disableSpacing>
          <IconButton component={RouterLink} to="/" title="Vissza"><ArrowBackIcon /></IconButton>
        </CardActions>
      </Card>
    </Container>;
  }

  const classes = useStyles();
  const { data: permissionData, loading: permissionLoading } = useCheckPermissionQuery({ variables: { PermissionKey: PermissionKeys.Warehouse.Inventory.Write } });
  const [inventoryStateId, setInventoryStateId] = useState(1);
  const { data, loading, error } = useListInventoriesQuery({
    skip: !permissionData?.CheckPermission,
    variables: {
      InventoryStateId: inventoryStateId === 0 ? undefined : inventoryStateId,
    },
  });

  if(permissionData && !permissionData.CheckPermission) return noPermission();

  const Inventory: FunctionComponent<{ Item: ListInventoryItem }> = (props) =>
  {

    function getAvatarClass(iventoryStateId?: number)
    {
      switch(iventoryStateId)
      {
        case 1: return classes.inventoryAvatarExecutable;
        case 2: return classes.inventoryAvatarExecuted;
        default: return classes.inventoryAvatar
      }
    }

    function UTCToLocal(date: string): string
    {
      if(!date) return "";
      let d = new Date(date);
      return d.toLocaleString('hu-HU', { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false });
    }

    return <Grid item xs={12} sm={6} md={4} lg={3}>
      <Card className={classes.card}>
        <CardHeader title={`#${props.Item.InventoryId}`}
          subheader={`Leltár: ${props.Item.InventoryState?.Name}`}
          avatar={<Avatar className={getAvatarClass(props.Item.InventoryState?.InventoryStateId ?? undefined)} ><AssignmentOutlinedIcon /></Avatar>}
        />
        <CardContent>
          <Grid container>
            <Grid item className={classes.item}><Typography>{UTCToLocal(props.Item.BeginAt)}</Typography></Grid>
            {props.Item.EndAt ? <Grid item className={classes.item}><Typography>{UTCToLocal(props.Item.EndAt)}: </Typography></Grid> : <></>}
          </Grid>
        </CardContent>
        {props.Item.InventoryState?.InventoryStateId === 1 ? <CardActions>
          <Button
            component={RouterLink}
            to={`/inventory/${props.Item.InventoryId}`}
            variant="contained"
            size="small"
            startIcon={<AssignmentOutlinedIcon />}
          >Végrehajtás
          </Button>
        </CardActions>
          : <></>}
      </Card>
    </Grid>;
  }

  const Inventories: FunctionComponent<{ Items: ListInventoryItem[] }> = (props) =>
  {
    return <Grid container className={classes.root}>
      {props.Items.map(i => <Inventory Item={i} key={i.InventoryId} />)}
    </Grid>;
  }

  const States: FunctionComponent = () =>
  {
    if(loading || permissionLoading) return <Box className={classes.progress}><CircularProgress color="secondary" /></Box>;
    if(error) return <Typography>{error.message}</Typography>;
    if(data?.ListInventories.Items.length === 0) return <Grid container className={classes.root}>
      <Typography>Nincs megjeleníthető elem.</Typography>
    </Grid>;
    return <Inventories Items={data!.ListInventories.Items} />;
  }
  const onInventoryStateIdChange = (event: React.MouseEvent<HTMLElement>, newInventoryStateId: number) =>
  {
    setInventoryStateId(newInventoryStateId);
  };

  return (
    <Box>
      <Box className={classes.filters} >
        <ToggleButtonGroup value={inventoryStateId} exclusive onChange={onInventoryStateIdChange}>
          <ToggleButton value={1}><FilterListIcon /></ToggleButton>
          <ToggleButton value={0}><ClearIcon /></ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <States />
    </Box>
  );
}
