import { Avatar, Badge, Card, CardActions, CardContent, CardHeader, CircularProgress, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { green, red, yellow } from "@material-ui/core/colors";
import SizeIcon from '@material-ui/icons/AspectRatio';
import DeviationsIcon from '@material-ui/icons/AssignmentOutlined';
import LastReceiptItemIcon from '@material-ui/icons/BookmarkBorder';
import PhotosIcon from '@material-ui/icons/CameraAltOutlined';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import WeightIcon from '@material-ui/icons/Speed';
import React, { FunctionComponent } from "react";
import { GetReceiptItem } from "../../graphql/generated/graphql";
import Counter from "./Counter";

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  cardHeader: {
    "& .MuiCardHeader-title": {
      fontWeight: "bold",
    },
  },
  cardContent: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: 0,
    paddingBottom: 0,
  },
  warehouseAvatar: {
    color: theme.palette.action.active,
  },
  warehouseAvatarProgress: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.background.paper,
  },
  warehouseAvatarExecutable: {
    backgroundColor: yellow[600],
    color: theme.palette.getContrastText(yellow[600]),
  },
  warehouseAvatarExecuted: {
    backgroundColor: green[600],
    color: theme.palette.getContrastText(green[600]),
  },
  item: {
    marginRight: theme.spacing(1),
  },
  remaining: {
    color: theme.palette.getContrastText(red[600]),
    backgroundColor: red[600],
  },
  completed: {
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[600],
  },
  lastReceiptItem: {
    backgroundColor: theme.palette.background.paper,
    color: green[600],
  },
  diffBadge: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(0.5),
    "& .MuiBadge-badge": {
      backgroundColor: yellow[600],
      color: theme.palette.getContrastText(yellow[600]),
    },
  },
}));

export default function Component(props: {
  Item: GetReceiptItem,
  Icon: JSX.Element,
  Subheader: string,
  IsProgress: boolean,
  Remaining: number,
  Bookmark: boolean,
  Ref: React.RefObject<HTMLElement>
})
{
  const classes = useStyles();

  const CountBadge: FunctionComponent<{ count: number }> = (props) =>
  {
    if(props.count > 0) return <Badge badgeContent={props.count} className={classes.diffBadge}>{props.children}</Badge>;
    return null;
  }

  const DiffBadge: FunctionComponent<{ Amounts: { Fact: number | null | undefined, Indicated: number | null | undefined }[] }> = (props) =>
  {
    const count = props.Amounts.reduce((p, a) => a.Fact && a.Indicated && a.Fact !== a.Indicated ? p + 1 : p, 0);
    return <CountBadge count={count}>{props.children}</CountBadge>
  }

  function getAvatarClass(item: GetReceiptItem): string
  {
    if(props.IsProgress) return classes.warehouseAvatarProgress;
    if(item.Execute)
    {
      if(props.Remaining === 0) return classes.warehouseAvatarExecuted;
      return classes.warehouseAvatarExecutable;
    }
    return classes.warehouseAvatar;
  }

  function getIcon(item: GetReceiptItem)
  {
    if(props.IsProgress) return <CircularProgress size={40} />;
    if(item.Execute) return props.Icon; //<PackageIcon />
    return <LocalShippingOutlinedIcon />
  }

  function iF(indicated: number | null | undefined, fact: number | null | undefined): string
  {
    if(fact === null || fact === undefined || fact === indicated) return `${indicated ?? '-'}`;
    return `${indicated ?? '-'}/${fact ?? '-'}`
  }

  return <Grid item xs={12} sm={6} md={4} lg={3}>
    <Card className={classes.card} ref={props.Ref}>
      <CardHeader className={classes.cardHeader} title={props.Item.PackageNumber}
        subheader={props.Item.Execute ? `${props.Subheader}: ${props.Item.StorageArea}` : 'Ellenőrzés'}
        avatar={<Avatar className={getAvatarClass(props.Item)}>{getIcon(props.Item)}</Avatar>}
        action={props.Bookmark ? <Avatar className={classes.lastReceiptItem}><LastReceiptItemIcon /></Avatar> : <></>}
      />
      <CardContent className={classes.cardContent}>
        <Grid container>
          <Grid item className={classes.item}><Typography>{props.Item.PackageName}</Typography></Grid>
        </Grid>
        <Grid container>
          <Grid item className={classes.item}><Typography>{props.Item.Quantity} {props.Item.UnitName} - </Typography></Grid>
          <Grid item className={classes.item}><Typography>{iF(props.Item.IndicatedWeight, props.Item.FactWeight)} kg, </Typography></Grid>
          <Grid item className={classes.item}><Typography>{iF(props.Item.IndicatedLength, props.Item.FactLength)}×{iF(props.Item.IndicatedWidth, props.Item.FactWidth)}×{iF(props.Item.IndicatedHeight, props.Item.FactHeight)}</Typography></Grid>
        </Grid>
        <Grid container>
          <Grid item className={classes.item}><Typography>{props.Item.DestinationPartner.Name}</Typography></Grid>
        </Grid>
      </CardContent>
      {props.Item.Execute ? <CardActions>
        <Counter className={classes.remaining} count={props.Remaining} limit={1} />
        <Counter className={classes.completed} count={props.Remaining} limit={1} />
        <DiffBadge Amounts={[{
          Fact: props.Item.FactWeight,
          Indicated: props.Item.IndicatedWeight
        }]}>
          <WeightIcon />
        </DiffBadge>
        <DiffBadge Amounts={[{
          Fact: props.Item.FactLength,
          Indicated: props.Item.IndicatedLength
        }, {
          Fact: props.Item.FactWidth,
          Indicated: props.Item.IndicatedWidth
        }, {
          Fact: props.Item.FactHeight,
          Indicated: props.Item.IndicatedHeight
        },]}>
          <SizeIcon />
        </DiffBadge>
        <CountBadge count={props.Item.Deviations.length}><DeviationsIcon /></CountBadge>
        <CountBadge count={props.Item.Photos.length}><PhotosIcon /></CountBadge>
      </CardActions> : <></>}
    </Card>
  </Grid>;
}
