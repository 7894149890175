import { Avatar, Box, Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { green, yellow } from "@material-ui/core/colors";
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import BusinessIcon from '@material-ui/icons/Business';
import ClearIcon from '@material-ui/icons/Clear';
import FilterListIcon from '@material-ui/icons/FilterList';
import KeyboardCapslockIcon from '@material-ui/icons/KeyboardCapslock';
import LastPageIcon from '@material-ui/icons/LastPage';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import React, { FunctionComponent, useState } from "react";
import { Link as RouterLink, useRouteMatch } from "react-router-dom";
import { ListReceiptItem, useListReceiptsQuery } from "../../graphql/generated/graphql";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
  },
  card: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  warehouseAvatar: {
    color: theme.palette.action.active,
  },
  warehouseAvatarExecutable: {
    backgroundColor: yellow[600],
    color: theme.palette.getContrastText(yellow[600]),
  },
  warehouseAvatarExecuted: {
    backgroundColor: green[600],
    color: theme.palette.getContrastText(green[600]),
  },
  item: {
    marginRight: theme.spacing(1),
  },
  filters: {
    marginLeft: "auto",
    marginRight: "auto",
    paddingRight: theme.spacing(1),
    width: 48 * 2,
    marginBottom: theme.spacing(2),
  },
  progress: {
    marginLeft: "auto",
    marginRight: "auto",
    width: 40,
  },
}));

export default function Component()
{
  const classes = useStyles();
  const match = useRouteMatch<{ receiptTypeId: string }>("/receipts/:receiptTypeId?");
  const [receiptStateId, setReceiptStateId] = useState(2);
  const { data, loading, error } = useListReceiptsQuery({
    variables: {
      ReceiptTypeId: Number.parseInt(match?.params.receiptTypeId ?? ""),
      ReceiptStateId: receiptStateId === 0 ? undefined : receiptStateId,
    },
  });

  const Receipt: FunctionComponent<{ Item: ListReceiptItem }> = (props) =>
  {

    function getIcon(receiptTypeId?: number)
    {
      switch(receiptTypeId)
      {
        case 1: return <LastPageIcon />;
        case 2: return <KeyboardCapslockIcon />;
        default: return <BusinessIcon />;
      }
    }

    function getAvatarClass(receiptStateId?: number)
    {
      switch(receiptStateId)
      {
        case 1: return classes.warehouseAvatar;
        case 2: return classes.warehouseAvatarExecutable;
        case 3: return classes.warehouseAvatarExecuted;
        default: return classes.warehouseAvatar
      }
    }

    function getButton(item: ListReceiptItem)
    {
      switch(item.ReceiptType?.ReceiptTypeId)
      {
        case 1: return <Button
          component={RouterLink}
          to={`/reception/${item.ReceiptId}`}
          variant="contained"
          size="small"
          startIcon={getIcon(props.Item.ReceiptType?.ReceiptTypeId ?? undefined)}
        >{props.Item.ReceiptType?.Name}
        </Button>;
        case 2: return <>
          {item.PackageCount ?
            <Button
              component={RouterLink}
              to={`/picking/${item.ReceiptId}`}
              disabled={!!item.PickedAt}
              variant="contained"
              size="small"
              startIcon={<AddShoppingCartIcon />}
            >Előkészítés</Button>
            : <></>}
          <Button
            component={RouterLink}
            to={`/release/${item.ReceiptId}`}
            disabled={!(item.PickedAt || item.AdHocItems)}
            variant="contained"
            size="small"
            startIcon={getIcon(props.Item.ReceiptType?.ReceiptTypeId ?? undefined)}
          >{props.Item.ReceiptType?.Name}</Button>
        </>;
        default: return <></>;
      }
    }

    return <Grid item xs={12} sm={6} md={4} lg={3}>
      <Card className={classes.card}>
        <CardHeader title={`#${props.Item.ReceiptId}`}
          subheader={`${props.Item.ReceiptType?.Name}: ${props.Item.ReceiptState?.Name}${props.Item.Ramp?.Name ? ", Rámpa: " + props.Item.Ramp?.Name : ""}`}
          avatar={<Avatar className={getAvatarClass(props.Item.ReceiptState?.ReceiptStateId ?? undefined)} >{getIcon(props.Item.ReceiptType?.ReceiptTypeId ?? undefined)}</Avatar>} />
        <CardContent>
          <Grid container>
            {props.Item.Date ? <Grid item className={classes.item}><Typography>{props.Item.Date}: </Typography></Grid> : <></>}
            {props.Item.Partner?.Name ? <Grid item className={classes.item}><Typography>{props.Item.Partner?.Name} </Typography></Grid> : <></>}
            <Grid item><Typography>{props.Item.TruckPlateNumber} {props.Item.TrailerPlateNumber}</Typography></Grid>
          </Grid>
        </CardContent>
        {props.Item.ReceiptState?.ReceiptStateId === 2 ? <CardActions>
          {getButton(props.Item)}
        </CardActions>
          : <></>}
      </Card>
    </Grid>;
  }

  const Receipts: FunctionComponent<{ Items: ListReceiptItem[] }> = (props) =>
  {
    return <Grid container className={classes.root}>
      {props.Items.map(i => <Receipt Item={i} key={i.ReceiptId} />)}
    </Grid>;
  }


  const States: FunctionComponent = () =>
  {
    if(loading) return <Box className={classes.progress}><CircularProgress color="secondary" /></Box>;
    if(error) return <Typography>{error.message}</Typography>;
    return <Receipts Items={data!.ListReceipts.Items} />;
  }

  const onReceiptStateIdChange = (event: React.MouseEvent<HTMLElement>, newReceiptStateId: number) =>
  {
    setReceiptStateId(newReceiptStateId);
  };

  return (
    <Box>
      <Box className={classes.filters} >
        <ToggleButtonGroup value={receiptStateId} exclusive onChange={onReceiptStateIdChange}>
          <ToggleButton value={2}><FilterListIcon /></ToggleButton>
          <ToggleButton value={0}><ClearIcon /></ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <States />
    </Box>
  );
}
