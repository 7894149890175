import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Theme, Typography } from "@material-ui/core";
import yellow from "@material-ui/core/colors/yellow";
import WarningOutlinedIcon from '@material-ui/icons/WarningOutlined';
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    marginRight: theme.spacing(1),
    float: "left",
    color: yellow[800],
  },
  iconParagraph: {
  }
}));


export default function Component(props: {
  onOK: () => void,
  onCancel: () => void
})
{
  const classes = useStyles();
  return <Dialog open>
    <DialogTitle>Címkék nyomtatása</DialogTitle>
    <DialogContent>
      <WarningOutlinedIcon fontSize="large" className={classes.icon} />
      <Typography component="span" className={classes.iconParagraph}>Indulhat az összes címke nyomtatása?</Typography>
    </DialogContent>
    <DialogActions>
      <Button color="primary" variant="contained" onClick={() => props.onOK()}>Igen</Button>
      <Button color="primary" variant="contained" onClick={() => props.onCancel()}>Nem</Button>
    </DialogActions>
  </Dialog>
}
