import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, makeStyles, Theme } from "@material-ui/core";
import CameraIcon from '@material-ui/icons/CameraOutlined';
import React, { useCallback, useRef } from "react";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    zIndex: 1,
    color: 'white',
  },
  video: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  actions: {
    justifyContent: "center"
  },
  icon: {
    fontSize: 64,
    color: 'white',
  }
}));

function useHookWithRefCallback<T>(init: (current: T) => void, remove: (current: T) => void)
{
  const ref = useRef<T>(null)
  const setRef = useCallback((node: T | null) =>
  {
    if(ref.current)
    {
      remove(ref.current);
    }

    if(node)
    {
      init(node);
    }
    (ref as any).current = node
    // eslint-disable-next-line
  }, [])
  return [ref, setRef]
}

export default function Component(props: {
  dialogOpen: boolean,
  onClose: (picture: Blob) => void,
})
{
  const classes = useStyles();
  const [ref, setRef] = useHookWithRefCallback<HTMLVideoElement>(current =>
  {
    (async () =>
    {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: {
          facingMode: 'environment',
        },
      });
      current.srcObject = mediaStream;
    })();
  }, current =>
  {
    (current.srcObject as MediaStream).getVideoTracks().forEach(t => t.stop());
    current.srcObject = null;
  });

  const handleCapture = () =>
  {
    (async () =>
    {
      const current = (ref as React.RefObject<HTMLVideoElement>).current!;
      const track = (current.srcObject as MediaStream).getVideoTracks()[0];
      // @ts-ignore
      const imageCapture = new ImageCapture(track);
      const blob = await imageCapture.takePhoto();
      track.stop();
      props.onClose(blob);
    })();
  };

  if(!props.dialogOpen) return null;

  return (
    <Dialog fullScreen open={props.dialogOpen}>
      <DialogTitle className={classes.title}>Új fotó</DialogTitle>
      <DialogContent>
        <video className={classes.video} ref={setRef} autoPlay></video>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <IconButton onClick={handleCapture}> <CameraIcon className={classes.icon} /></IconButton>
      </DialogActions>
    </Dialog>
  );
}
