import { Avatar, Card, CardActions, CardContent, CardHeader, Grid, Theme, Typography, makeStyles } from "@material-ui/core";
import { green, red, yellow } from "@material-ui/core/colors";
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import PackageIcon from '@material-ui/icons/WidgetsOutlined';
import React from "react";
import { GetInventoryItem } from "../../graphql/generated/graphql";

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  cardHeader: {
    "& .MuiCardHeader-title": {
      fontWeight: "bold",
    },
  },
  cardContent: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: 0,
    paddingBottom: 0,
  },
  warehouseAvatar: {
    color: theme.palette.action.active,
  },
  warehouseAvatarProgress: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.background.paper,
  },
  warehouseAvatarExecutable: {
    backgroundColor: yellow[600],
    color: theme.palette.getContrastText(yellow[600]),
  },
  warehouseAvatarExecuted: {
    backgroundColor: green[600],
    color: theme.palette.getContrastText(green[600]),
  },
  item: {
    marginRight: theme.spacing(1),
  },
  remaining: {
    color: theme.palette.getContrastText(red[600]),
    backgroundColor: red[600],
  },
  completed: {
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[600],
  },
  lastReceiptItem: {
    backgroundColor: theme.palette.background.paper,
    color: green[600],
  },
  diffBadge: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(0.5),
    "& .MuiBadge-badge": {
      backgroundColor: yellow[600],
      color: theme.palette.getContrastText(yellow[600]),
    },
  },
}));


export default function Component(props: {
  Item: GetInventoryItem,
})
{
  const classes = useStyles();

  function iF(indicated: number | null | undefined, fact: number | null | undefined): string
  {
    if(fact === null || fact === undefined || fact === indicated) return `${indicated ?? '-'}`;
    return `${indicated ?? '-'}/${fact ?? '-'}`
  }

  function formatDateTime(date: string)
  {
    if(!date) return "";
    let d = new Date(date);
    let td = new Date();
    if(d.getFullYear() === td.getFullYear() && d.getMonth() === td.getMonth() && d.getDate() === td.getDate())
      return d.toLocaleString('hu-HU', { hour: '2-digit', minute: '2-digit', hour12: false });
    return d.toLocaleString('hu-HU', { month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false });
  }

  return <Grid item xs={12} sm={6} md={4} lg={3}>
    <Card className={classes.card}>
      <CardHeader className={classes.cardHeader} title={props.Item.PackageNumber}
        subheader={`Tárhely: ${props.Item.StorageArea ?? ''}`}
        avatar={<Avatar className={classes.warehouseAvatarExecuted}><PackageIcon /></Avatar>}
      // action={props.Bookmark ? <Avatar className={classes.lastReceiptItem}><LastReceiptItemIcon /></Avatar> : <></>}
      />
      <CardContent className={classes.cardContent}>
        <Grid container>
          <Grid item className={classes.item}><Typography>{props.Item.PackageName}</Typography></Grid>
        </Grid>
        <Grid container>
          <Grid item className={classes.item}><Typography>{props.Item.Quantity} {props.Item.UnitName} - </Typography></Grid>
          <Grid item className={classes.item}><Typography>{iF(props.Item.IndicatedWeight, props.Item.FactWeight)} kg, </Typography></Grid>
          <Grid item className={classes.item}><Typography>{iF(props.Item.IndicatedLength, props.Item.FactLength)}×{iF(props.Item.IndicatedWidth, props.Item.FactWidth)}×{iF(props.Item.IndicatedHeight, props.Item.FactHeight)}</Typography></Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Grid container>
          <Grid item><AssignmentOutlinedIcon /></Grid>
          <Grid item className={classes.item}><Typography>{`${formatDateTime(props.Item.CheckedAt)} ${props.Item.UserName}`}</Typography></Grid>
        </Grid>
      </CardActions>
    </Card>
  </Grid>;

}
