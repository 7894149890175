import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Theme, Typography } from "@material-ui/core";
import yellow from "@material-ui/core/colors/yellow";
import WarningOutlinedIcon from '@material-ui/icons/WarningOutlined';
import React, { FunctionComponent, useState } from "react";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    marginRight: theme.spacing(1),
    float: "left",
    color: yellow[800],
  },
  iconParagraph: {
  }
}));


const ConfirmDialog: FunctionComponent<{
  title: string,
  message: string,
  onConfirm: () => void,
  open: boolean,
  setOpen: (open: boolean) => void,
}> = (props) =>
  {
    const classes = useStyles();
    return <Dialog open={props.open}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <WarningOutlinedIcon fontSize="large" className={classes.icon} />
        <Typography component="span" className={classes.iconParagraph}>{props.message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={() => props.onConfirm()}>Igen</Button>
        <Button color="primary" variant="contained" onClick={() => props.setOpen(false)}>Nem</Button>
      </DialogActions>
    </Dialog>
  }

export const useConfirmDialog = (title: string, message: string, onConfirm: () => void): { ConfirmDialog: FunctionComponent, openConfirmDialog: () => void, closeConfirmDialog: () => void } =>
{
  const [open, setOpen] = useState(false);

  const ConfirmDialogComponent: FunctionComponent = () => (
    <ConfirmDialog
      open={open}
      setOpen={setOpen}
      title={title}
      message={message}
      onConfirm={onConfirm}
    />
  );

  return {
    ConfirmDialog: ConfirmDialogComponent,
    openConfirmDialog: () => setOpen(true),
    closeConfirmDialog: () => setOpen(false)
  };
};
