import { Avatar } from "@material-ui/core";
import React from "react";

export default function Component(props: {
  count: number,
  className: string,
  limit: number
})
{
  if(props.count > props.limit) return <Avatar className={props.className}>{props.count}</Avatar>;
  return <></>;
}
