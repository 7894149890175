import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type AddInventoryPackageResult = {
  __typename?: 'AddInventoryPackageResult';
  Error?: Maybe<Scalars['String']>;
  Item?: Maybe<GetInventoryItem>;
  PackageCount?: Maybe<Scalars['Int']>;
};

export type AddReleasedPackageResult = {
  __typename?: 'AddReleasedPackageResult';
  Error?: Maybe<Scalars['String']>;
  Item?: Maybe<GetReceiptItem>;
};

export type AddedInventoryPackage = {
  InventoryId: Scalars['Int'];
  PackageNumber: Scalars['String'];
};

export type AddedReleasePackage = {
  PackageNumber: Scalars['String'];
  ReceiptId: Scalars['Int'];
};

export type DeviationType = {
  __typename?: 'DeviationType';
  DeviationTypeId: Scalars['Int'];
  Name: Scalars['String'];
  Photo: Scalars['Boolean'];
};

export type GetInventoryItem = {
  __typename?: 'GetInventoryItem';
  CheckedAt: Scalars['String'];
  FactHeight?: Maybe<Scalars['Float']>;
  FactLength?: Maybe<Scalars['Float']>;
  FactWeight?: Maybe<Scalars['Int']>;
  FactWidth?: Maybe<Scalars['Float']>;
  IndicatedHeight?: Maybe<Scalars['Float']>;
  IndicatedLength?: Maybe<Scalars['Float']>;
  IndicatedWeight?: Maybe<Scalars['Int']>;
  IndicatedWidth?: Maybe<Scalars['Float']>;
  InventoryPackageId: Scalars['Int'];
  PackageName: Scalars['String'];
  PackageNumber: Scalars['String'];
  Quantity: Scalars['Int'];
  StorageArea?: Maybe<Scalars['String']>;
  UnitName: Scalars['String'];
  UserName: Scalars['String'];
};

export type GetInventoryResult = {
  __typename?: 'GetInventoryResult';
  Inventory: ListInventoryItem;
  Items: Array<GetInventoryItem>;
  PackageCount: Scalars['Int'];
};

export type GetReceiptItem = {
  __typename?: 'GetReceiptItem';
  DestinationPartner: Partner;
  Deviations: Array<Scalars['Int']>;
  Execute: Scalars['Boolean'];
  ExecutedAt?: Maybe<Scalars['String']>;
  FactHeight?: Maybe<Scalars['Float']>;
  FactLength?: Maybe<Scalars['Float']>;
  FactWeight?: Maybe<Scalars['Int']>;
  FactWidth?: Maybe<Scalars['Float']>;
  IndicatedHeight?: Maybe<Scalars['Float']>;
  IndicatedLength?: Maybe<Scalars['Float']>;
  IndicatedWeight?: Maybe<Scalars['Int']>;
  IndicatedWidth?: Maybe<Scalars['Float']>;
  OrderNumber: Scalars['String'];
  PackageName: Scalars['String'];
  PackageNumber: Scalars['String'];
  Photos: Array<Scalars['String']>;
  PickedAt?: Maybe<Scalars['String']>;
  Quantity: Scalars['Int'];
  ReceiptPackageId: Scalars['Int'];
  StorageArea?: Maybe<Scalars['String']>;
  UnitName: Scalars['String'];
};

export type GetReceiptResult = {
  __typename?: 'GetReceiptResult';
  DeviationTypes: Array<DeviationType>;
  Items: Array<GetReceiptItem>;
  Receipt: ListReceiptItem;
};

export type InventoryState = {
  __typename?: 'InventoryState';
  InventoryStateId: Scalars['Int'];
  Name: Scalars['String'];
};

export type ListInventoriesResult = {
  __typename?: 'ListInventoriesResult';
  Items: Array<ListInventoryItem>;
};

export type ListInventoryItem = {
  __typename?: 'ListInventoryItem';
  BeginAt: Scalars['String'];
  EndAt?: Maybe<Scalars['String']>;
  InventoryId: Scalars['Int'];
  InventoryState: InventoryState;
};

export type ListReceiptItem = {
  __typename?: 'ListReceiptItem';
  AdHocItems?: Maybe<Scalars['Boolean']>;
  Date?: Maybe<Scalars['String']>;
  PackageCount?: Maybe<Scalars['Int']>;
  Partner?: Maybe<Partner>;
  PickedAt?: Maybe<Scalars['String']>;
  Ramp?: Maybe<Ramp>;
  ReceiptId: Scalars['Int'];
  ReceiptState?: Maybe<ReceiptState>;
  ReceiptType?: Maybe<ReceiptType>;
  TrailerPlateNumber?: Maybe<Scalars['String']>;
  TruckPlateNumber?: Maybe<Scalars['String']>;
};

export type ListReceiptsResult = {
  __typename?: 'ListReceiptsResult';
  Items: Array<ListReceiptItem>;
};

export type LoginResult = {
  __typename?: 'LoginResult';
  success: Scalars['Boolean'];
  token?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  AddInventoryPackage: AddInventoryPackageResult;
  AddReleasedPackage: AddReleasedPackageResult;
  InventoryExecuted: Scalars['Boolean'];
  PackagePicked: PackagePickedResult;
  PackageReceived: PackageReceivedResult;
  PackageReleased: PackageReleasedResult;
  PrintLabels: Scalars['Boolean'];
  ReceiptExecuted: Scalars['Boolean'];
  ReceiptPicked: Scalars['Boolean'];
  login: LoginResult;
  logout: Scalars['Boolean'];
  saveToken: Scalars['Boolean'];
};


export type MutationAddInventoryPackageArgs = {
  AddedInventoryPackage: AddedInventoryPackage;
};


export type MutationAddReleasedPackageArgs = {
  AddedReleasePackage: AddedReleasePackage;
};


export type MutationInventoryExecutedArgs = {
  InventoryId: Scalars['Int'];
};


export type MutationPackagePickedArgs = {
  PickedPackage: PickedPackage;
};


export type MutationPackageReceivedArgs = {
  ReceivedPackage: ReceivedPackage;
};


export type MutationPackageReleasedArgs = {
  ReleasedPackage: ReleasedPackage;
};


export type MutationPrintLabelsArgs = {
  ReceiptId: Scalars['Int'];
};


export type MutationReceiptExecutedArgs = {
  ReceiptId: Scalars['Int'];
};


export type MutationReceiptPickedArgs = {
  ReceiptId: Scalars['Int'];
};


export type MutationLoginArgs = {
  name: Scalars['String'];
  password: Scalars['String'];
};


export type MutationSaveTokenArgs = {
  token: Scalars['String'];
};

export type PackagePickedResult = {
  __typename?: 'PackagePickedResult';
  PickedAt?: Maybe<Scalars['String']>;
};

export type PackageReceivedResult = {
  __typename?: 'PackageReceivedResult';
  ExecutedAt?: Maybe<Scalars['String']>;
  Photos: Array<Scalars['String']>;
};

export type PackageReleasedResult = {
  __typename?: 'PackageReleasedResult';
  ExecutedAt?: Maybe<Scalars['String']>;
};

export type Partner = {
  __typename?: 'Partner';
  Name?: Maybe<Scalars['String']>;
  PartnerId?: Maybe<Scalars['Int']>;
};

export type PickedPackage = {
  ReceiptPackageId: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  CheckPermission: Scalars['Boolean'];
  GetInventory: GetInventoryResult;
  GetReceipt: GetReceiptResult;
  ListInventories: ListInventoriesResult;
  ListReceipts: ListReceiptsResult;
  isLoggedIn: Scalars['Boolean'];
};


export type QueryCheckPermissionArgs = {
  PermissionKey: Scalars['String'];
};


export type QueryGetInventoryArgs = {
  InventoryId: Scalars['Int'];
  MaxItemCount: Scalars['Int'];
};


export type QueryGetReceiptArgs = {
  ReceiptId: Scalars['Int'];
};


export type QueryListInventoriesArgs = {
  InventoryStateId?: InputMaybe<Scalars['Int']>;
};


export type QueryListReceiptsArgs = {
  ReceiptStateId?: InputMaybe<Scalars['Int']>;
  ReceiptTypeId?: InputMaybe<Scalars['Int']>;
};

export type Ramp = {
  __typename?: 'Ramp';
  Name?: Maybe<Scalars['String']>;
  RampId?: Maybe<Scalars['Int']>;
};

export type ReceiptState = {
  __typename?: 'ReceiptState';
  Name?: Maybe<Scalars['String']>;
  ReceiptStateId?: Maybe<Scalars['Int']>;
};

export type ReceiptType = {
  __typename?: 'ReceiptType';
  Name?: Maybe<Scalars['String']>;
  ReceiptTypeId?: Maybe<Scalars['Int']>;
};

export type ReceivedPackage = {
  Deviations: Array<Scalars['Int']>;
  FactHeight?: InputMaybe<Scalars['Float']>;
  FactLength?: InputMaybe<Scalars['Float']>;
  FactWeight?: InputMaybe<Scalars['Int']>;
  FactWidth?: InputMaybe<Scalars['Float']>;
  Photos: Array<Scalars['String']>;
  ReceiptPackageId: Scalars['Int'];
};

export type ReleasedPackage = {
  ReceiptPackageId: Scalars['Int'];
};

export type LoginMutationVariables = Exact<{
  name: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'LoginResult', success: boolean, token?: string | null | undefined } };

export type SaveTokenMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type SaveTokenMutation = { __typename?: 'Mutation', saveToken: boolean };

export type IsLoggedInQueryVariables = Exact<{ [key: string]: never; }>;


export type IsLoggedInQuery = { __typename?: 'Query', isLoggedIn: boolean };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout: boolean };

export type CheckPermissionQueryVariables = Exact<{
  PermissionKey: Scalars['String'];
}>;


export type CheckPermissionQuery = { __typename?: 'Query', CheckPermission: boolean };

export type ListInventoriesQueryVariables = Exact<{
  InventoryStateId?: InputMaybe<Scalars['Int']>;
}>;


export type ListInventoriesQuery = { __typename?: 'Query', ListInventories: { __typename?: 'ListInventoriesResult', Items: Array<{ __typename?: 'ListInventoryItem', InventoryId: number, BeginAt: string, EndAt?: string | null | undefined, InventoryState: { __typename?: 'InventoryState', InventoryStateId: number, Name: string } }> } };

export type GetInventoryQueryVariables = Exact<{
  InventoryId: Scalars['Int'];
  MaxItemCount: Scalars['Int'];
}>;


export type GetInventoryQuery = { __typename?: 'Query', GetInventory: { __typename?: 'GetInventoryResult', PackageCount: number, Inventory: { __typename?: 'ListInventoryItem', InventoryId: number, BeginAt: string, EndAt?: string | null | undefined, InventoryState: { __typename?: 'InventoryState', InventoryStateId: number, Name: string } }, Items: Array<{ __typename?: 'GetInventoryItem', InventoryPackageId: number, PackageName: string, Quantity: number, UnitName: string, IndicatedWeight?: number | null | undefined, IndicatedLength?: number | null | undefined, IndicatedWidth?: number | null | undefined, IndicatedHeight?: number | null | undefined, FactWeight?: number | null | undefined, FactLength?: number | null | undefined, FactWidth?: number | null | undefined, FactHeight?: number | null | undefined, PackageNumber: string, StorageArea?: string | null | undefined, CheckedAt: string, UserName: string }> } };

export type AddInventoryPackageMutationVariables = Exact<{
  AddedInventoryPackage: AddedInventoryPackage;
}>;


export type AddInventoryPackageMutation = { __typename?: 'Mutation', AddInventoryPackage: { __typename?: 'AddInventoryPackageResult', Error?: string | null | undefined, PackageCount?: number | null | undefined, Item?: { __typename?: 'GetInventoryItem', InventoryPackageId: number, PackageName: string, Quantity: number, UnitName: string, IndicatedWeight?: number | null | undefined, IndicatedLength?: number | null | undefined, IndicatedWidth?: number | null | undefined, IndicatedHeight?: number | null | undefined, FactWeight?: number | null | undefined, FactLength?: number | null | undefined, FactWidth?: number | null | undefined, FactHeight?: number | null | undefined, PackageNumber: string, StorageArea?: string | null | undefined, CheckedAt: string, UserName: string } | null | undefined } };

export type InventoryExecutedMutationVariables = Exact<{
  InventoryId: Scalars['Int'];
}>;


export type InventoryExecutedMutation = { __typename?: 'Mutation', InventoryExecuted: boolean };

export type ListReceiptsQueryVariables = Exact<{
  ReceiptTypeId?: InputMaybe<Scalars['Int']>;
  ReceiptStateId?: InputMaybe<Scalars['Int']>;
}>;


export type ListReceiptsQuery = { __typename?: 'Query', ListReceipts: { __typename?: 'ListReceiptsResult', Items: Array<{ __typename?: 'ListReceiptItem', ReceiptId: number, Date?: string | null | undefined, PickedAt?: string | null | undefined, TruckPlateNumber?: string | null | undefined, TrailerPlateNumber?: string | null | undefined, PackageCount?: number | null | undefined, AdHocItems?: boolean | null | undefined, ReceiptType?: { __typename?: 'ReceiptType', ReceiptTypeId?: number | null | undefined, Name?: string | null | undefined } | null | undefined, ReceiptState?: { __typename?: 'ReceiptState', ReceiptStateId?: number | null | undefined, Name?: string | null | undefined } | null | undefined, Partner?: { __typename?: 'Partner', PartnerId?: number | null | undefined, Name?: string | null | undefined } | null | undefined, Ramp?: { __typename?: 'Ramp', RampId?: number | null | undefined, Name?: string | null | undefined } | null | undefined }> } };

export type GetReceiptQueryVariables = Exact<{
  ReceiptId: Scalars['Int'];
}>;


export type GetReceiptQuery = { __typename?: 'Query', GetReceipt: { __typename?: 'GetReceiptResult', Receipt: { __typename?: 'ListReceiptItem', ReceiptId: number, Date?: string | null | undefined, TruckPlateNumber?: string | null | undefined, TrailerPlateNumber?: string | null | undefined, ReceiptType?: { __typename?: 'ReceiptType', ReceiptTypeId?: number | null | undefined, Name?: string | null | undefined } | null | undefined, ReceiptState?: { __typename?: 'ReceiptState', ReceiptStateId?: number | null | undefined, Name?: string | null | undefined } | null | undefined, Partner?: { __typename?: 'Partner', PartnerId?: number | null | undefined, Name?: string | null | undefined } | null | undefined, Ramp?: { __typename?: 'Ramp', RampId?: number | null | undefined, Name?: string | null | undefined } | null | undefined }, DeviationTypes: Array<{ __typename?: 'DeviationType', DeviationTypeId: number, Name: string, Photo: boolean }>, Items: Array<{ __typename?: 'GetReceiptItem', Execute: boolean, ReceiptPackageId: number, ExecutedAt?: string | null | undefined, OrderNumber: string, PackageName: string, Quantity: number, UnitName: string, IndicatedWeight?: number | null | undefined, IndicatedLength?: number | null | undefined, IndicatedWidth?: number | null | undefined, IndicatedHeight?: number | null | undefined, FactWeight?: number | null | undefined, FactLength?: number | null | undefined, FactWidth?: number | null | undefined, FactHeight?: number | null | undefined, PackageNumber: string, StorageArea?: string | null | undefined, Deviations: Array<number>, Photos: Array<string>, DestinationPartner: { __typename?: 'Partner', PartnerId?: number | null | undefined, Name?: string | null | undefined } }> } };

export type GetPickingReceiptQueryVariables = Exact<{
  ReceiptId: Scalars['Int'];
}>;


export type GetPickingReceiptQuery = { __typename?: 'Query', GetReceipt: { __typename?: 'GetReceiptResult', Receipt: { __typename?: 'ListReceiptItem', ReceiptId: number, Date?: string | null | undefined, TruckPlateNumber?: string | null | undefined, TrailerPlateNumber?: string | null | undefined, ReceiptType?: { __typename?: 'ReceiptType', ReceiptTypeId?: number | null | undefined, Name?: string | null | undefined } | null | undefined, ReceiptState?: { __typename?: 'ReceiptState', ReceiptStateId?: number | null | undefined, Name?: string | null | undefined } | null | undefined, Partner?: { __typename?: 'Partner', PartnerId?: number | null | undefined, Name?: string | null | undefined } | null | undefined, Ramp?: { __typename?: 'Ramp', RampId?: number | null | undefined, Name?: string | null | undefined } | null | undefined }, Items: Array<{ __typename?: 'GetReceiptItem', Execute: boolean, ReceiptPackageId: number, PickedAt?: string | null | undefined, OrderNumber: string, PackageName: string, Quantity: number, UnitName: string, IndicatedWeight?: number | null | undefined, IndicatedLength?: number | null | undefined, IndicatedWidth?: number | null | undefined, IndicatedHeight?: number | null | undefined, FactWeight?: number | null | undefined, FactLength?: number | null | undefined, FactWidth?: number | null | undefined, FactHeight?: number | null | undefined, PackageNumber: string, StorageArea?: string | null | undefined, Deviations: Array<number>, Photos: Array<string>, DestinationPartner: { __typename?: 'Partner', PartnerId?: number | null | undefined, Name?: string | null | undefined } }> } };

export type GetReleaseReceiptQueryVariables = Exact<{
  ReceiptId: Scalars['Int'];
}>;


export type GetReleaseReceiptQuery = { __typename?: 'Query', GetReceipt: { __typename?: 'GetReceiptResult', Receipt: { __typename?: 'ListReceiptItem', ReceiptId: number, Date?: string | null | undefined, TruckPlateNumber?: string | null | undefined, TrailerPlateNumber?: string | null | undefined, AdHocItems?: boolean | null | undefined, ReceiptType?: { __typename?: 'ReceiptType', ReceiptTypeId?: number | null | undefined, Name?: string | null | undefined } | null | undefined, ReceiptState?: { __typename?: 'ReceiptState', ReceiptStateId?: number | null | undefined, Name?: string | null | undefined } | null | undefined, Partner?: { __typename?: 'Partner', PartnerId?: number | null | undefined, Name?: string | null | undefined } | null | undefined, Ramp?: { __typename?: 'Ramp', RampId?: number | null | undefined, Name?: string | null | undefined } | null | undefined }, Items: Array<{ __typename?: 'GetReceiptItem', Execute: boolean, ReceiptPackageId: number, ExecutedAt?: string | null | undefined, OrderNumber: string, PackageName: string, Quantity: number, UnitName: string, IndicatedWeight?: number | null | undefined, IndicatedLength?: number | null | undefined, IndicatedWidth?: number | null | undefined, IndicatedHeight?: number | null | undefined, FactWeight?: number | null | undefined, FactLength?: number | null | undefined, FactWidth?: number | null | undefined, FactHeight?: number | null | undefined, PackageNumber: string, StorageArea?: string | null | undefined, Deviations: Array<number>, Photos: Array<string>, DestinationPartner: { __typename?: 'Partner', PartnerId?: number | null | undefined, Name?: string | null | undefined } }> } };

export type PrintLabelsMutationVariables = Exact<{
  ReceiptId: Scalars['Int'];
}>;


export type PrintLabelsMutation = { __typename?: 'Mutation', PrintLabels: boolean };

export type PackageReceivedMutationVariables = Exact<{
  ReceivedPackage: ReceivedPackage;
}>;


export type PackageReceivedMutation = { __typename?: 'Mutation', PackageReceived: { __typename?: 'PackageReceivedResult', ExecutedAt?: string | null | undefined, Photos: Array<string> } };

export type ReceiptExecutedMutationVariables = Exact<{
  ReceiptId: Scalars['Int'];
}>;


export type ReceiptExecutedMutation = { __typename?: 'Mutation', ReceiptExecuted: boolean };

export type PackagePickedMutationVariables = Exact<{
  PickedPackage: PickedPackage;
}>;


export type PackagePickedMutation = { __typename?: 'Mutation', PackagePicked: { __typename?: 'PackagePickedResult', PickedAt?: string | null | undefined } };

export type ReceiptPickedMutationVariables = Exact<{
  ReceiptId: Scalars['Int'];
}>;


export type ReceiptPickedMutation = { __typename?: 'Mutation', ReceiptPicked: boolean };

export type PackageReleasedMutationVariables = Exact<{
  ReleasedPackage: ReleasedPackage;
}>;


export type PackageReleasedMutation = { __typename?: 'Mutation', PackageReleased: { __typename?: 'PackageReleasedResult', ExecutedAt?: string | null | undefined } };

export type AddReleasedPackageMutationVariables = Exact<{
  AddedReleasePackage: AddedReleasePackage;
}>;


export type AddReleasedPackageMutation = { __typename?: 'Mutation', AddReleasedPackage: { __typename?: 'AddReleasedPackageResult', Error?: string | null | undefined, Item?: { __typename?: 'GetReceiptItem', Execute: boolean, ReceiptPackageId: number, ExecutedAt?: string | null | undefined, OrderNumber: string, PackageName: string, Quantity: number, UnitName: string, IndicatedWeight?: number | null | undefined, IndicatedLength?: number | null | undefined, IndicatedWidth?: number | null | undefined, IndicatedHeight?: number | null | undefined, FactWeight?: number | null | undefined, FactLength?: number | null | undefined, FactWidth?: number | null | undefined, FactHeight?: number | null | undefined, PackageNumber: string, StorageArea?: string | null | undefined, Deviations: Array<number>, Photos: Array<string>, DestinationPartner: { __typename?: 'Partner', PartnerId?: number | null | undefined, Name?: string | null | undefined } } | null | undefined } };


export const LoginDocument = gql`
    mutation login($name: String!, $password: String!) {
  login(name: $name, password: $password) {
    success
    token
  }
}
    `;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      name: // value for 'name'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const SaveTokenDocument = gql`
    mutation saveToken($token: String!) {
  saveToken(token: $token) @client
}
    `;

/**
 * __useSaveTokenMutation__
 *
 * To run a mutation, you first call `useSaveTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveTokenMutation, { data, loading, error }] = useSaveTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useSaveTokenMutation(baseOptions?: Apollo.MutationHookOptions<SaveTokenMutation, SaveTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveTokenMutation, SaveTokenMutationVariables>(SaveTokenDocument, options);
      }
export type SaveTokenMutationHookResult = ReturnType<typeof useSaveTokenMutation>;
export type SaveTokenMutationResult = Apollo.MutationResult<SaveTokenMutation>;
export type SaveTokenMutationOptions = Apollo.BaseMutationOptions<SaveTokenMutation, SaveTokenMutationVariables>;
export const IsLoggedInDocument = gql`
    query isLoggedIn {
  isLoggedIn @client
}
    `;

/**
 * __useIsLoggedInQuery__
 *
 * To run a query within a React component, call `useIsLoggedInQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsLoggedInQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsLoggedInQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsLoggedInQuery(baseOptions?: Apollo.QueryHookOptions<IsLoggedInQuery, IsLoggedInQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsLoggedInQuery, IsLoggedInQueryVariables>(IsLoggedInDocument, options);
      }
export function useIsLoggedInLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsLoggedInQuery, IsLoggedInQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsLoggedInQuery, IsLoggedInQueryVariables>(IsLoggedInDocument, options);
        }
export type IsLoggedInQueryHookResult = ReturnType<typeof useIsLoggedInQuery>;
export type IsLoggedInLazyQueryHookResult = ReturnType<typeof useIsLoggedInLazyQuery>;
export type IsLoggedInQueryResult = Apollo.QueryResult<IsLoggedInQuery, IsLoggedInQueryVariables>;
export const LogoutDocument = gql`
    mutation logout {
  logout @client
}
    `;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const CheckPermissionDocument = gql`
    query CheckPermission($PermissionKey: String!) {
  CheckPermission(PermissionKey: $PermissionKey)
}
    `;

/**
 * __useCheckPermissionQuery__
 *
 * To run a query within a React component, call `useCheckPermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckPermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckPermissionQuery({
 *   variables: {
 *      PermissionKey: // value for 'PermissionKey'
 *   },
 * });
 */
export function useCheckPermissionQuery(baseOptions: Apollo.QueryHookOptions<CheckPermissionQuery, CheckPermissionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckPermissionQuery, CheckPermissionQueryVariables>(CheckPermissionDocument, options);
      }
export function useCheckPermissionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckPermissionQuery, CheckPermissionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckPermissionQuery, CheckPermissionQueryVariables>(CheckPermissionDocument, options);
        }
export type CheckPermissionQueryHookResult = ReturnType<typeof useCheckPermissionQuery>;
export type CheckPermissionLazyQueryHookResult = ReturnType<typeof useCheckPermissionLazyQuery>;
export type CheckPermissionQueryResult = Apollo.QueryResult<CheckPermissionQuery, CheckPermissionQueryVariables>;
export const ListInventoriesDocument = gql`
    query ListInventories($InventoryStateId: Int) {
  ListInventories(InventoryStateId: $InventoryStateId) {
    Items {
      InventoryId
      InventoryState {
        InventoryStateId
        Name
      }
      BeginAt
      EndAt
    }
  }
}
    `;

/**
 * __useListInventoriesQuery__
 *
 * To run a query within a React component, call `useListInventoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListInventoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListInventoriesQuery({
 *   variables: {
 *      InventoryStateId: // value for 'InventoryStateId'
 *   },
 * });
 */
export function useListInventoriesQuery(baseOptions?: Apollo.QueryHookOptions<ListInventoriesQuery, ListInventoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListInventoriesQuery, ListInventoriesQueryVariables>(ListInventoriesDocument, options);
      }
export function useListInventoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListInventoriesQuery, ListInventoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListInventoriesQuery, ListInventoriesQueryVariables>(ListInventoriesDocument, options);
        }
export type ListInventoriesQueryHookResult = ReturnType<typeof useListInventoriesQuery>;
export type ListInventoriesLazyQueryHookResult = ReturnType<typeof useListInventoriesLazyQuery>;
export type ListInventoriesQueryResult = Apollo.QueryResult<ListInventoriesQuery, ListInventoriesQueryVariables>;
export const GetInventoryDocument = gql`
    query GetInventory($InventoryId: Int!, $MaxItemCount: Int!) {
  GetInventory(InventoryId: $InventoryId, MaxItemCount: $MaxItemCount) {
    Inventory {
      InventoryId
      InventoryState {
        InventoryStateId
        Name
      }
      BeginAt
      EndAt
    }
    Items {
      InventoryPackageId
      PackageName
      Quantity
      UnitName
      IndicatedWeight
      IndicatedLength
      IndicatedWidth
      IndicatedHeight
      FactWeight
      FactLength
      FactWidth
      FactHeight
      PackageNumber
      StorageArea
      CheckedAt
      UserName
    }
    PackageCount
  }
}
    `;

/**
 * __useGetInventoryQuery__
 *
 * To run a query within a React component, call `useGetInventoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInventoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInventoryQuery({
 *   variables: {
 *      InventoryId: // value for 'InventoryId'
 *      MaxItemCount: // value for 'MaxItemCount'
 *   },
 * });
 */
export function useGetInventoryQuery(baseOptions: Apollo.QueryHookOptions<GetInventoryQuery, GetInventoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInventoryQuery, GetInventoryQueryVariables>(GetInventoryDocument, options);
      }
export function useGetInventoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInventoryQuery, GetInventoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInventoryQuery, GetInventoryQueryVariables>(GetInventoryDocument, options);
        }
export type GetInventoryQueryHookResult = ReturnType<typeof useGetInventoryQuery>;
export type GetInventoryLazyQueryHookResult = ReturnType<typeof useGetInventoryLazyQuery>;
export type GetInventoryQueryResult = Apollo.QueryResult<GetInventoryQuery, GetInventoryQueryVariables>;
export const AddInventoryPackageDocument = gql`
    mutation AddInventoryPackage($AddedInventoryPackage: AddedInventoryPackage!) {
  AddInventoryPackage(AddedInventoryPackage: $AddedInventoryPackage) {
    Error
    Item {
      InventoryPackageId
      PackageName
      Quantity
      UnitName
      IndicatedWeight
      IndicatedLength
      IndicatedWidth
      IndicatedHeight
      FactWeight
      FactLength
      FactWidth
      FactHeight
      PackageNumber
      StorageArea
      CheckedAt
      UserName
    }
    PackageCount
  }
}
    `;

/**
 * __useAddInventoryPackageMutation__
 *
 * To run a mutation, you first call `useAddInventoryPackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddInventoryPackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addInventoryPackageMutation, { data, loading, error }] = useAddInventoryPackageMutation({
 *   variables: {
 *      AddedInventoryPackage: // value for 'AddedInventoryPackage'
 *   },
 * });
 */
export function useAddInventoryPackageMutation(baseOptions?: Apollo.MutationHookOptions<AddInventoryPackageMutation, AddInventoryPackageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddInventoryPackageMutation, AddInventoryPackageMutationVariables>(AddInventoryPackageDocument, options);
      }
export type AddInventoryPackageMutationHookResult = ReturnType<typeof useAddInventoryPackageMutation>;
export type AddInventoryPackageMutationResult = Apollo.MutationResult<AddInventoryPackageMutation>;
export type AddInventoryPackageMutationOptions = Apollo.BaseMutationOptions<AddInventoryPackageMutation, AddInventoryPackageMutationVariables>;
export const InventoryExecutedDocument = gql`
    mutation InventoryExecuted($InventoryId: Int!) {
  InventoryExecuted(InventoryId: $InventoryId)
}
    `;

/**
 * __useInventoryExecutedMutation__
 *
 * To run a mutation, you first call `useInventoryExecutedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInventoryExecutedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inventoryExecutedMutation, { data, loading, error }] = useInventoryExecutedMutation({
 *   variables: {
 *      InventoryId: // value for 'InventoryId'
 *   },
 * });
 */
export function useInventoryExecutedMutation(baseOptions?: Apollo.MutationHookOptions<InventoryExecutedMutation, InventoryExecutedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InventoryExecutedMutation, InventoryExecutedMutationVariables>(InventoryExecutedDocument, options);
      }
export type InventoryExecutedMutationHookResult = ReturnType<typeof useInventoryExecutedMutation>;
export type InventoryExecutedMutationResult = Apollo.MutationResult<InventoryExecutedMutation>;
export type InventoryExecutedMutationOptions = Apollo.BaseMutationOptions<InventoryExecutedMutation, InventoryExecutedMutationVariables>;
export const ListReceiptsDocument = gql`
    query ListReceipts($ReceiptTypeId: Int, $ReceiptStateId: Int) {
  ListReceipts(ReceiptTypeId: $ReceiptTypeId, ReceiptStateId: $ReceiptStateId) {
    Items {
      ReceiptId
      Date
      ReceiptType {
        ReceiptTypeId
        Name
      }
      ReceiptState {
        ReceiptStateId
        Name
      }
      PickedAt
      Partner {
        PartnerId
        Name
      }
      TruckPlateNumber
      TrailerPlateNumber
      Ramp {
        RampId
        Name
      }
      PackageCount
      AdHocItems
    }
  }
}
    `;

/**
 * __useListReceiptsQuery__
 *
 * To run a query within a React component, call `useListReceiptsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListReceiptsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListReceiptsQuery({
 *   variables: {
 *      ReceiptTypeId: // value for 'ReceiptTypeId'
 *      ReceiptStateId: // value for 'ReceiptStateId'
 *   },
 * });
 */
export function useListReceiptsQuery(baseOptions?: Apollo.QueryHookOptions<ListReceiptsQuery, ListReceiptsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListReceiptsQuery, ListReceiptsQueryVariables>(ListReceiptsDocument, options);
      }
export function useListReceiptsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListReceiptsQuery, ListReceiptsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListReceiptsQuery, ListReceiptsQueryVariables>(ListReceiptsDocument, options);
        }
export type ListReceiptsQueryHookResult = ReturnType<typeof useListReceiptsQuery>;
export type ListReceiptsLazyQueryHookResult = ReturnType<typeof useListReceiptsLazyQuery>;
export type ListReceiptsQueryResult = Apollo.QueryResult<ListReceiptsQuery, ListReceiptsQueryVariables>;
export const GetReceiptDocument = gql`
    query GetReceipt($ReceiptId: Int!) {
  GetReceipt(ReceiptId: $ReceiptId) {
    Receipt {
      ReceiptId
      Date
      ReceiptType {
        ReceiptTypeId
        Name
      }
      ReceiptState {
        ReceiptStateId
        Name
      }
      Partner {
        PartnerId
        Name
      }
      TruckPlateNumber
      TrailerPlateNumber
      Ramp {
        RampId
        Name
      }
    }
    DeviationTypes {
      DeviationTypeId
      Name
      Photo
    }
    Items {
      Execute
      ReceiptPackageId
      ExecutedAt
      OrderNumber
      PackageName
      Quantity
      UnitName
      IndicatedWeight
      IndicatedLength
      IndicatedWidth
      IndicatedHeight
      FactWeight
      FactLength
      FactWidth
      FactHeight
      DestinationPartner {
        PartnerId
        Name
      }
      PackageNumber
      StorageArea
      Deviations
      Photos
    }
  }
}
    `;

/**
 * __useGetReceiptQuery__
 *
 * To run a query within a React component, call `useGetReceiptQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReceiptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReceiptQuery({
 *   variables: {
 *      ReceiptId: // value for 'ReceiptId'
 *   },
 * });
 */
export function useGetReceiptQuery(baseOptions: Apollo.QueryHookOptions<GetReceiptQuery, GetReceiptQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReceiptQuery, GetReceiptQueryVariables>(GetReceiptDocument, options);
      }
export function useGetReceiptLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReceiptQuery, GetReceiptQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReceiptQuery, GetReceiptQueryVariables>(GetReceiptDocument, options);
        }
export type GetReceiptQueryHookResult = ReturnType<typeof useGetReceiptQuery>;
export type GetReceiptLazyQueryHookResult = ReturnType<typeof useGetReceiptLazyQuery>;
export type GetReceiptQueryResult = Apollo.QueryResult<GetReceiptQuery, GetReceiptQueryVariables>;
export const GetPickingReceiptDocument = gql`
    query GetPickingReceipt($ReceiptId: Int!) {
  GetReceipt(ReceiptId: $ReceiptId) {
    Receipt {
      ReceiptId
      Date
      ReceiptType {
        ReceiptTypeId
        Name
      }
      ReceiptState {
        ReceiptStateId
        Name
      }
      Partner {
        PartnerId
        Name
      }
      TruckPlateNumber
      TrailerPlateNumber
      Ramp {
        RampId
        Name
      }
    }
    Items {
      Execute
      ReceiptPackageId
      PickedAt
      OrderNumber
      PackageName
      Quantity
      UnitName
      IndicatedWeight
      IndicatedLength
      IndicatedWidth
      IndicatedHeight
      FactWeight
      FactLength
      FactWidth
      FactHeight
      DestinationPartner {
        PartnerId
        Name
      }
      PackageNumber
      StorageArea
      Deviations
      Photos
    }
  }
}
    `;

/**
 * __useGetPickingReceiptQuery__
 *
 * To run a query within a React component, call `useGetPickingReceiptQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPickingReceiptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPickingReceiptQuery({
 *   variables: {
 *      ReceiptId: // value for 'ReceiptId'
 *   },
 * });
 */
export function useGetPickingReceiptQuery(baseOptions: Apollo.QueryHookOptions<GetPickingReceiptQuery, GetPickingReceiptQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPickingReceiptQuery, GetPickingReceiptQueryVariables>(GetPickingReceiptDocument, options);
      }
export function useGetPickingReceiptLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPickingReceiptQuery, GetPickingReceiptQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPickingReceiptQuery, GetPickingReceiptQueryVariables>(GetPickingReceiptDocument, options);
        }
export type GetPickingReceiptQueryHookResult = ReturnType<typeof useGetPickingReceiptQuery>;
export type GetPickingReceiptLazyQueryHookResult = ReturnType<typeof useGetPickingReceiptLazyQuery>;
export type GetPickingReceiptQueryResult = Apollo.QueryResult<GetPickingReceiptQuery, GetPickingReceiptQueryVariables>;
export const GetReleaseReceiptDocument = gql`
    query GetReleaseReceipt($ReceiptId: Int!) {
  GetReceipt(ReceiptId: $ReceiptId) {
    Receipt {
      ReceiptId
      Date
      ReceiptType {
        ReceiptTypeId
        Name
      }
      ReceiptState {
        ReceiptStateId
        Name
      }
      Partner {
        PartnerId
        Name
      }
      TruckPlateNumber
      TrailerPlateNumber
      Ramp {
        RampId
        Name
      }
      AdHocItems
    }
    Items {
      Execute
      ReceiptPackageId
      ExecutedAt
      OrderNumber
      PackageName
      Quantity
      UnitName
      IndicatedWeight
      IndicatedLength
      IndicatedWidth
      IndicatedHeight
      FactWeight
      FactLength
      FactWidth
      FactHeight
      DestinationPartner {
        PartnerId
        Name
      }
      PackageNumber
      StorageArea
      Deviations
      Photos
    }
  }
}
    `;

/**
 * __useGetReleaseReceiptQuery__
 *
 * To run a query within a React component, call `useGetReleaseReceiptQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReleaseReceiptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReleaseReceiptQuery({
 *   variables: {
 *      ReceiptId: // value for 'ReceiptId'
 *   },
 * });
 */
export function useGetReleaseReceiptQuery(baseOptions: Apollo.QueryHookOptions<GetReleaseReceiptQuery, GetReleaseReceiptQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReleaseReceiptQuery, GetReleaseReceiptQueryVariables>(GetReleaseReceiptDocument, options);
      }
export function useGetReleaseReceiptLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReleaseReceiptQuery, GetReleaseReceiptQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReleaseReceiptQuery, GetReleaseReceiptQueryVariables>(GetReleaseReceiptDocument, options);
        }
export type GetReleaseReceiptQueryHookResult = ReturnType<typeof useGetReleaseReceiptQuery>;
export type GetReleaseReceiptLazyQueryHookResult = ReturnType<typeof useGetReleaseReceiptLazyQuery>;
export type GetReleaseReceiptQueryResult = Apollo.QueryResult<GetReleaseReceiptQuery, GetReleaseReceiptQueryVariables>;
export const PrintLabelsDocument = gql`
    mutation PrintLabels($ReceiptId: Int!) {
  PrintLabels(ReceiptId: $ReceiptId)
}
    `;

/**
 * __usePrintLabelsMutation__
 *
 * To run a mutation, you first call `usePrintLabelsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrintLabelsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [printLabelsMutation, { data, loading, error }] = usePrintLabelsMutation({
 *   variables: {
 *      ReceiptId: // value for 'ReceiptId'
 *   },
 * });
 */
export function usePrintLabelsMutation(baseOptions?: Apollo.MutationHookOptions<PrintLabelsMutation, PrintLabelsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PrintLabelsMutation, PrintLabelsMutationVariables>(PrintLabelsDocument, options);
      }
export type PrintLabelsMutationHookResult = ReturnType<typeof usePrintLabelsMutation>;
export type PrintLabelsMutationResult = Apollo.MutationResult<PrintLabelsMutation>;
export type PrintLabelsMutationOptions = Apollo.BaseMutationOptions<PrintLabelsMutation, PrintLabelsMutationVariables>;
export const PackageReceivedDocument = gql`
    mutation PackageReceived($ReceivedPackage: ReceivedPackage!) {
  PackageReceived(ReceivedPackage: $ReceivedPackage) {
    ExecutedAt
    Photos
  }
}
    `;

/**
 * __usePackageReceivedMutation__
 *
 * To run a mutation, you first call `usePackageReceivedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePackageReceivedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [packageReceivedMutation, { data, loading, error }] = usePackageReceivedMutation({
 *   variables: {
 *      ReceivedPackage: // value for 'ReceivedPackage'
 *   },
 * });
 */
export function usePackageReceivedMutation(baseOptions?: Apollo.MutationHookOptions<PackageReceivedMutation, PackageReceivedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PackageReceivedMutation, PackageReceivedMutationVariables>(PackageReceivedDocument, options);
      }
export type PackageReceivedMutationHookResult = ReturnType<typeof usePackageReceivedMutation>;
export type PackageReceivedMutationResult = Apollo.MutationResult<PackageReceivedMutation>;
export type PackageReceivedMutationOptions = Apollo.BaseMutationOptions<PackageReceivedMutation, PackageReceivedMutationVariables>;
export const ReceiptExecutedDocument = gql`
    mutation ReceiptExecuted($ReceiptId: Int!) {
  ReceiptExecuted(ReceiptId: $ReceiptId)
}
    `;

/**
 * __useReceiptExecutedMutation__
 *
 * To run a mutation, you first call `useReceiptExecutedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReceiptExecutedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [receiptExecutedMutation, { data, loading, error }] = useReceiptExecutedMutation({
 *   variables: {
 *      ReceiptId: // value for 'ReceiptId'
 *   },
 * });
 */
export function useReceiptExecutedMutation(baseOptions?: Apollo.MutationHookOptions<ReceiptExecutedMutation, ReceiptExecutedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReceiptExecutedMutation, ReceiptExecutedMutationVariables>(ReceiptExecutedDocument, options);
      }
export type ReceiptExecutedMutationHookResult = ReturnType<typeof useReceiptExecutedMutation>;
export type ReceiptExecutedMutationResult = Apollo.MutationResult<ReceiptExecutedMutation>;
export type ReceiptExecutedMutationOptions = Apollo.BaseMutationOptions<ReceiptExecutedMutation, ReceiptExecutedMutationVariables>;
export const PackagePickedDocument = gql`
    mutation PackagePicked($PickedPackage: PickedPackage!) {
  PackagePicked(PickedPackage: $PickedPackage) {
    PickedAt
  }
}
    `;

/**
 * __usePackagePickedMutation__
 *
 * To run a mutation, you first call `usePackagePickedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePackagePickedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [packagePickedMutation, { data, loading, error }] = usePackagePickedMutation({
 *   variables: {
 *      PickedPackage: // value for 'PickedPackage'
 *   },
 * });
 */
export function usePackagePickedMutation(baseOptions?: Apollo.MutationHookOptions<PackagePickedMutation, PackagePickedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PackagePickedMutation, PackagePickedMutationVariables>(PackagePickedDocument, options);
      }
export type PackagePickedMutationHookResult = ReturnType<typeof usePackagePickedMutation>;
export type PackagePickedMutationResult = Apollo.MutationResult<PackagePickedMutation>;
export type PackagePickedMutationOptions = Apollo.BaseMutationOptions<PackagePickedMutation, PackagePickedMutationVariables>;
export const ReceiptPickedDocument = gql`
    mutation ReceiptPicked($ReceiptId: Int!) {
  ReceiptPicked(ReceiptId: $ReceiptId)
}
    `;

/**
 * __useReceiptPickedMutation__
 *
 * To run a mutation, you first call `useReceiptPickedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReceiptPickedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [receiptPickedMutation, { data, loading, error }] = useReceiptPickedMutation({
 *   variables: {
 *      ReceiptId: // value for 'ReceiptId'
 *   },
 * });
 */
export function useReceiptPickedMutation(baseOptions?: Apollo.MutationHookOptions<ReceiptPickedMutation, ReceiptPickedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReceiptPickedMutation, ReceiptPickedMutationVariables>(ReceiptPickedDocument, options);
      }
export type ReceiptPickedMutationHookResult = ReturnType<typeof useReceiptPickedMutation>;
export type ReceiptPickedMutationResult = Apollo.MutationResult<ReceiptPickedMutation>;
export type ReceiptPickedMutationOptions = Apollo.BaseMutationOptions<ReceiptPickedMutation, ReceiptPickedMutationVariables>;
export const PackageReleasedDocument = gql`
    mutation PackageReleased($ReleasedPackage: ReleasedPackage!) {
  PackageReleased(ReleasedPackage: $ReleasedPackage) {
    ExecutedAt
  }
}
    `;

/**
 * __usePackageReleasedMutation__
 *
 * To run a mutation, you first call `usePackageReleasedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePackageReleasedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [packageReleasedMutation, { data, loading, error }] = usePackageReleasedMutation({
 *   variables: {
 *      ReleasedPackage: // value for 'ReleasedPackage'
 *   },
 * });
 */
export function usePackageReleasedMutation(baseOptions?: Apollo.MutationHookOptions<PackageReleasedMutation, PackageReleasedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PackageReleasedMutation, PackageReleasedMutationVariables>(PackageReleasedDocument, options);
      }
export type PackageReleasedMutationHookResult = ReturnType<typeof usePackageReleasedMutation>;
export type PackageReleasedMutationResult = Apollo.MutationResult<PackageReleasedMutation>;
export type PackageReleasedMutationOptions = Apollo.BaseMutationOptions<PackageReleasedMutation, PackageReleasedMutationVariables>;
export const AddReleasedPackageDocument = gql`
    mutation AddReleasedPackage($AddedReleasePackage: AddedReleasePackage!) {
  AddReleasedPackage(AddedReleasePackage: $AddedReleasePackage) {
    Error
    Item {
      Execute
      ReceiptPackageId
      ExecutedAt
      OrderNumber
      PackageName
      Quantity
      UnitName
      IndicatedWeight
      IndicatedLength
      IndicatedWidth
      IndicatedHeight
      FactWeight
      FactLength
      FactWidth
      FactHeight
      DestinationPartner {
        PartnerId
        Name
      }
      PackageNumber
      StorageArea
      Deviations
      Photos
    }
  }
}
    `;

/**
 * __useAddReleasedPackageMutation__
 *
 * To run a mutation, you first call `useAddReleasedPackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddReleasedPackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addReleasedPackageMutation, { data, loading, error }] = useAddReleasedPackageMutation({
 *   variables: {
 *      AddedReleasePackage: // value for 'AddedReleasePackage'
 *   },
 * });
 */
export function useAddReleasedPackageMutation(baseOptions?: Apollo.MutationHookOptions<AddReleasedPackageMutation, AddReleasedPackageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddReleasedPackageMutation, AddReleasedPackageMutationVariables>(AddReleasedPackageDocument, options);
      }
export type AddReleasedPackageMutationHookResult = ReturnType<typeof useAddReleasedPackageMutation>;
export type AddReleasedPackageMutationResult = Apollo.MutationResult<AddReleasedPackageMutation>;
export type AddReleasedPackageMutationOptions = Apollo.BaseMutationOptions<AddReleasedPackageMutation, AddReleasedPackageMutationVariables>;