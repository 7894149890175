import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Theme } from "@material-ui/core";
import CancelIcon from '@material-ui/icons/CancelOutlined';
import { Result } from '@zxing/library';
import React from "react";
import BarcodeScanner from './BarcodeScanner';


const useStyles = makeStyles((theme: Theme) => ({
  title: {
    zIndex: 1,
    color: 'white',
  },
  cancelButton: {
    color: 'white',
  },
}));

export default function Component(props: {
  dialogOpen: boolean,
  onBarcodeUpdate: (arg0: unknown, arg1?: Result) => void,
  onCancel: () => void,
})
{
  const classes = useStyles();
  if(!props.dialogOpen) return null;
  return (
    <Dialog fullScreen open={props.dialogOpen}>
      <DialogTitle className={classes.title}>Vonalkód beolvasás</DialogTitle>
      <DialogContent>
        <BarcodeScanner
          onUpdate={props.onBarcodeUpdate}
        />
      </DialogContent>
      <DialogActions>
        <Button className={classes.cancelButton} startIcon={<CancelIcon />} onClick={props.onCancel}>Bezár</Button>
      </DialogActions>
    </Dialog>

  );
}
