import { Avatar, Card, CardActions, CardContent, CardHeader, Container, IconButton, List, ListItem, ListItemAvatar, ListItemText, makeStyles, Theme, Typography } from "@material-ui/core";
import { blue, green } from "@material-ui/core/colors";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import BusinessIcon from '@material-ui/icons/Business';
import KeyboardCapslockIcon from '@material-ui/icons/KeyboardCapslock';
import LastPageIcon from '@material-ui/icons/LastPage';
import { Omit } from '@material-ui/types';
import React from "react";
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { useCheckPermissionQuery } from "../../graphql/generated/graphql";
import PermissionKeys from "../../shared/PermissionKeys";

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    marginBottom: theme.spacing(1),
  },
  warehouseAvatar: {
    backgroundColor: green[500],
  },
  warehouseItemAvatar: {
    // backgroundColor: green[800],
    color: theme.palette.action.active,
  },
  demoAvatar: {
    backgroundColor: blue[500],
  },
}));

interface ListItemLinkProps
{
  icon?: React.ReactElement;
  primary: string;
  to: string;
}

function ListItemLink(props: ListItemLinkProps)
{
  const { icon, primary, to } = props;
  const classes = useStyles();

  const renderLink = React.useMemo(
    () =>
      React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to],
  );

  return (
    <li>
      <ListItem button component={renderLink}>
        {icon ? <ListItemAvatar><Avatar className={classes.warehouseItemAvatar}>{icon}</Avatar></ListItemAvatar> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

export default function Component()
{
  const classes = useStyles();
  const { data } = useCheckPermissionQuery({ variables: { PermissionKey: PermissionKeys.Warehouse.Inventory.Write } });

  return (
    <Container maxWidth="xs">
      <Card className={classes.card}>
        <CardHeader title="Raktár" subheader="Áruraktározási funkciók" avatar={<Avatar className={classes.warehouseAvatar} >R</Avatar>} />
        <CardContent>
          <List component="nav" aria-label="main mailbox folders">
            <ListItemLink to="/receipts/1" primary="Áruátvétel" icon={<LastPageIcon />} />
            <ListItemLink to="/receipts/2" primary="Árukiadás" icon={<KeyboardCapslockIcon />} />
            <ListItemLink to="/receipts" primary="Raktározás" icon={<BusinessIcon />} />
            {data?.CheckPermission ? <ListItemLink to="/inventories" primary="Leltár" icon={<AssignmentOutlinedIcon />} /> : null}
          </List>
        </CardContent>
      </Card>
      <Card>
        <CardHeader title="Demo" subheader="Vonalkód beolvasás" avatar={<Avatar className={classes.demoAvatar}>D</Avatar>} />
        <CardContent>
          <Typography variant="body2" component="p">Próbáld ki a "Vonalkód demo"-t</Typography>
        </CardContent>
        <CardActions disableSpacing>
          <IconButton component={RouterLink} to="/barcodedemo" title="Vonalkód Demo"><ArrowForwardIcon /></IconButton>
        </CardActions>
      </Card>
    </Container>
  );
}
