import { CssBaseline } from '@material-ui/core';
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import { IsLoggedInDocument } from './graphql/generated/graphql';
import { setContext } from '@apollo/client/link/context'; 
// eslint-disable-next-line
import { blue, cyan, grey, pink } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import React, { FunctionComponent } from 'react';
import ReactDOM from 'react-dom';
import 'typeface-roboto-multilang/latin-ext.css';
import App from './App';
import usePaletteType from './shared/Hooks/usePaletteType';
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import useAppInsights from "./useAppInsights";
export interface AppConfig
{
  uri: string,
  env: string,
  aiik: string,
}

const Index: FunctionComponent<{ appConfig: AppConfig }> = (props) =>
{
  const paletteType = usePaletteType();
  const { reactPlugin } = useAppInsights(props.appConfig);

  // ««·· palette
  const palettes = {
    background: {
      dark: {
        default: '#303030',
      },
      light: {
        default: grey[300],
      },
    },
    primary: {
      dark: {
        contrastText: grey[900],
        main: blue[500],
      },
      light: {
        contrastText: 'white',
        main: blue[500],
      },
    },
    secondary: {
      dark: cyan,
      light: { main: pink[300] },
    },
    text: {
      primary: {
        dark: grey[300],
        light: '#000',
      },
    },
  }
  // ··»»

  // ««·· theme
  const theme = createMuiTheme({
    palette: {
      background: {
        default: palettes.background[paletteType].default,
      },
      primary: palettes.primary[paletteType],
      secondary: palettes.secondary[paletteType],
      text: {
        primary: palettes.text.primary[paletteType],
      },
      type: paletteType,
    },
    typography: {
    },
  });
  // ··»»

  
  const httpLink = createHttpLink({
    uri: `${props.appConfig.uri ?? document.baseURI}graphql`,
  });

  const authLink = setContext((_, { headers }) =>
  {
    const token = localStorage.getItem('token');
    return {
      headers: {
        ...headers,
        authorization: token ? token : "",
      }
    }
  });

  const cache = new InMemoryCache({});
  const isLoggedIn = localStorage.getItem('token') !== null;
  cache.writeQuery({ query: IsLoggedInDocument, data: { isLoggedIn } });
  const client = new ApolloClient({
    cache: cache,
    link: authLink.concat(httpLink),
    resolvers: {
      Mutation: {
        logout: (_: any, __: any, { cache }: any) =>
        {
          cache.writeQuery({ query: IsLoggedInDocument, data: { isLoggedIn: false } });
          localStorage.removeItem('token');
          return true;
        },
        saveToken: (_: any, { token }: any, { cache }: any) =>
        {
          cache.writeQuery({ query: IsLoggedInDocument, data: { isLoggedIn: true } });
          localStorage.setItem('token', token);
          return true;
        },
      },
      Query: {
        isLoggedIn: (_: any, { isLoggedIn }: any, { cache }: any) =>
        {
          return localStorage.getItem('token') !== null;
        }
      },
    },
  });
  
  return (
    <React.StrictMode>
      <AppInsightsContext.Provider value={reactPlugin}>
        <ApolloProvider client={client}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <App appConfig={props.appConfig} />
          </ThemeProvider>
        </ApolloProvider>
      </AppInsightsContext.Provider>
    </React.StrictMode>
  );
}

async function main()
{
  const response = await fetch('/app.config.json');
  const config = await response.json() as AppConfig;
  ReactDOM.render(<Index appConfig={config} />, document.getElementById('root'));
}

main();
