import { AppBar, Badge, Box, createMuiTheme, Divider, IconButton, ListItemIcon, ListItemText, makeStyles, Menu, MenuItem, Theme, ThemeProvider, Toolbar, Typography } from "@material-ui/core";
import { blue, grey, pink } from "@material-ui/core/colors";
import AccountCircle from '@material-ui/icons/AccountCircle';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import GetAppIcon from '@material-ui/icons/GetApp';
import React, { FunctionComponent, useEffect, useState } from "react";
import { useLogoutMutation } from "../graphql/generated/graphql";
import usePaletteType from "./Hooks/usePaletteType";
import { ServiceWorkerState } from './Hooks/useServiceWorker';
import ServiceWorkerIcon from './ServiceWorkerIcon';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: 0,
    paddingRight: 0,
  },
  grow: {
    flexGrow: 1,
  },
  bottomNavigation: {
    top: "auto",
    bottom: theme.spacing(1),
    boxShadow: "inherit"
  },
}));

const Component: FunctionComponent<{ serviceWorkerState: ServiceWorkerState, env: string }> = props =>
{
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const menuId = 'primary-search-account-menu';
  
  const [logout] = useLogoutMutation();

  const onLogoutClick = async () =>
  {
    setAnchorEl(null);
    await logout();
  };
  
  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) =>
  {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () =>
  {
    setAnchorEl(null);
  };

  const [refreshApp, setRefreshApp] = useState(false);

  useEffect(() =>
  {
    if(refreshApp)
    {
      (async () =>
      {
        const registration = await navigator.serviceWorker.ready;
        await registration.unregister();
        window.location.reload();
      })();
    }
  }, [refreshApp]);

  const onRefreshApp = () =>
  {
    setAnchorEl(null);
    setRefreshApp(true);
  };

  const [refreshSW, setRefreshSW] = useState(false);

  useEffect(() =>
  {
    if(refreshSW)
    {
      setRefreshSW(false);
      (async () =>
      {
        const registration = await navigator.serviceWorker.ready;
        await registration.update();
      })();
    }
  }, [refreshSW]);

  const onRefreshSW = () =>
  {
    setAnchorEl(null);
    if(props.serviceWorkerState === ServiceWorkerState.Ready)
    {
      setRefreshSW(true);
    }
  };

  const paletteType = usePaletteType();

  const palettes = {
    primary: {
      dark: {
        contrastText: grey[300],
        main: '#35363a',
      },
      light: {
        contrastText: 'white',
        main: blue[500],
      },
    },
    secondary: {
      dark: { main: blue[500] },
      light: { main: pink[200] },
    },
    text: {
      primary: {
        dark: grey[300],
        light: '#000',
      },
    },
  }

  const theme = createMuiTheme({
    palette: {
      primary: palettes.primary[paletteType],
      secondary: palettes.secondary[paletteType],
      text: {
        primary: palettes.text.primary[paletteType],
      },
      type: paletteType,
    },
    typography: {
    },
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <AppBar position="sticky">
          <Toolbar variant="dense">
            <Typography variant="h6" noWrap>
              {process.env.REACT_APP_NAME}
            </Typography>
            <div className={classes.grow} />
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleProfileMenuOpen}
            >
              <Badge badgeContent={props.serviceWorkerState === ServiceWorkerState.UpdateAvailable ? 1 : 0} color="secondary">
                <AccountCircle />
              </Badge>
            </IconButton>
          </Toolbar>
        </AppBar>
      </ThemeProvider>
      <Box className={classes.root}>
        <>{props.children}</>
      </Box>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        {props.serviceWorkerState === ServiceWorkerState.UpdateAvailable ?
          <MenuItem onClick={onRefreshApp}>
            <ListItemIcon>
              <GetAppIcon color="secondary" />
            </ListItemIcon>
            <ListItemText primary="Frissítés" />
          </MenuItem>
          : null}
        <MenuItem onClick={onRefreshSW}>
          <ListItemIcon>
            <ServiceWorkerIcon state={props.serviceWorkerState} />
          </ListItemIcon>
          <ListItemText primary={`${props.env} ${process.env.REACT_APP_BUILDNUMBER}`} />
        </MenuItem>
        <Divider />
        <MenuItem onClick={onLogoutClick}>
          <ListItemIcon>
            <ArrowBackIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Kijelentkezés" />
        </MenuItem>
      </Menu>
    </>
  );
}

export default Component;
