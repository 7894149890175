import { Tooltip } from '@material-ui/core';
import CloudDoneOutlinedIcon from '@material-ui/icons/CloudDoneOutlined';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import CloudOffOutlinedIcon from '@material-ui/icons/CloudOffOutlined';
import CloudOutlinedIcon from '@material-ui/icons/CloudOutlined';
import React from 'react';
import { ServiceWorkerState } from './Hooks/useServiceWorker';

export default function Component(props: { state: ServiceWorkerState })
{
  switch(props.state)
  {
    case ServiceWorkerState.Unknown: return (<Tooltip title="Ismeretlen"><CloudOffOutlinedIcon fontSize="small" /></Tooltip>);
    case ServiceWorkerState.Installed: return (<Tooltip title="Telpítve"><CloudDoneOutlinedIcon fontSize="small" /></Tooltip>);
    case ServiceWorkerState.Ready: return (<Tooltip title="Kész"><CloudOutlinedIcon fontSize="small" /></Tooltip >);
    case ServiceWorkerState.UpdateAvailable: return (<Tooltip title="Frissítés letöltve..."><CloudDownloadOutlinedIcon fontSize="small" /></Tooltip >);
  }
}
