import { makeStyles, Theme } from '@material-ui/core';
import { Result } from '@zxing/library';
import React, { useEffect, useRef } from 'react';
import BarcodeScannerComponent from './BarcodeScannerComponent';


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    '& video': {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
}));

export default function Component(props: { onUpdate: (arg0: unknown, arg1?: Result) => void; })
{
  const classes = useStyles();
  const rootEl = useRef(null);

  useEffect(() =>
  {
    if(rootEl?.current)
    {
      let current = rootEl.current as any;
      current.firstChild.removeAttribute("width");
      current.firstChild.removeAttribute("height");
    }
  }, [rootEl]);

  return <div ref={rootEl} className={classes.root}>
    <BarcodeScannerComponent onUpdate={props.onUpdate} />
  </div>
}
