import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Theme, Typography } from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import SuccessIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/ErrorOutlined';
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
  iconError: {
    marginRight: theme.spacing(1),
    float: "left",
    color: red[800],
  },
  iconSuccess: {
    marginRight: theme.spacing(1),
    float: "left",
    color: green[800],
  },
  iconParagraph: {
  }
}));


export default function Component(props: {
  isSuccess: boolean,
  onClose: () => void
})
{
  const classes = useStyles();
  return <Dialog open>
    <DialogTitle>Címkék nyomtatása</DialogTitle>
    <DialogContent>
      {props.isSuccess ?
        <>
          <SuccessIcon fontSize="large" className={classes.iconSuccess} />
          <Typography component="span" className={classes.iconParagraph}>A cimkék nyomtatása sikeresen elindult!</Typography>
        </>
        :
        <>
          <ErrorIcon fontSize="large" className={classes.iconError} />
          <Typography component="span" className={classes.iconParagraph}>A címkék nyomtatása nem sikerült!</Typography>
        </>
      }
    </DialogContent>
    <DialogActions>
      <Button color="primary" variant="contained" onClick={() => props.onClose()}>Bezár</Button>
    </DialogActions>
  </Dialog>
}
