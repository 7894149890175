import { makeStyles, Paper, Theme } from "@material-ui/core";
import React, { useEffect, useState } from "react";


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "fixed",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    padding: theme.spacing(2),
    fontSize: 18,
  }
}));

export default function Component(props: {
  enabled: boolean,
  onBarcodeUpdate: (barcode: string) => void,
})
{
  const classes = useStyles();

  const [input, setInput] = useState('');

  const keyPressHandler = (e: KeyboardEvent) =>
  {
    if(!props.enabled) return;
    if(e.key === "Enter")
    {
      e.preventDefault();
      if(input) props.onBarcodeUpdate(input);
      setInput("");
      return;
    }
    if(e.key === "Backspace" && input.length > 0)
    {
      e.preventDefault();
      setInput(input.slice(0, -1));
      return;
    }
    if(e.ctrlKey && e.key === 'v')
    {
      e.preventDefault();
      navigator.clipboard.readText().then(setInput);
      return;
    }
    if(e.key.length === 1 && e.key.match(/^[0-9a-zA-Z!@#$%^&*()_+{}|:"<>?~`\-=[\]\\;',./]$/))
    {
      e.preventDefault();
      setInput(input + e.key);
      return;
    }
  };

  useEffect(() =>
  {
    window.addEventListener('keydown', keyPressHandler);
    return () =>
    {
      window.removeEventListener('keydown', keyPressHandler);
    };
  });

  if(!input) return null;

  return (
    <Paper className={classes.root}>
      {input}
    </Paper>
  );
}
