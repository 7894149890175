import { useState } from "react";

export default function ()
{
  const [value, setValue] = useState<number[]>([]);

  const contains = (e: number) =>
  {
    return value.indexOf(e) > -1;
  }

  const add = (e: number) =>
  {
    value.push(e);
    setValue(value);
  }

  const remove = (e: number) =>
  {
    value.splice(value.indexOf(e), 1);
    setValue(value.slice());
  }

  return {
    contains,
    add,
    remove,
  };
}
