import { Button, Card, CardActions, CardContent, CircularProgress, Container, Grid, makeStyles, Snackbar, TextField, Theme, Tooltip } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLoginMutation, useSaveTokenMutation } from '../../graphql/generated/graphql';
import { AppConfig } from '../../index';
import logo from '../../logo.svg';
import useInput from '../../shared/Hooks/useInput';
import { ServiceWorkerState } from '../../shared/Hooks/useServiceWorker';
import ServiceWorkerIcon from '../../shared/ServiceWorkerIcon';

const useStyles = makeStyles((theme: Theme) => ({
  '@global': {
    'html, body': {
      overflow: 'hidden',
    },
  },
  root: {
    padding: theme.spacing(1),
  },
  logobox: {
    textAlign: "center",
    backgroundColor: blue[500],
    margin: -1 * theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  logo: {
    height: 64,
  },
  welcome: {
    textAlign: "center",
    fontWeight: 'bold',
    color: "#f0f0f0",
    textShadow: '2px 2px 2px #000000',
  },
  buildnumber: {
    position: "absolute",
    bottom: theme.spacing(1),
    width: "100%",
    textAlign: "center",
    fontSize: 10,
    '& svg': {
      height: 13,
      width: 13,
      verticalAlign: "text-bottom",
    }
  }
}));

function Alert(props: AlertProps)
{
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Component(props: {
  serviceWorkerState: ServiceWorkerState,
  appConfig: AppConfig,
  returnurl?: string
})
{
  const classes = useStyles();
  const [login, { loading }] = useLoginMutation();
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const name = useInput();
  const password = useInput();
  const [saveToken] = useSaveTokenMutation();
  const history = useHistory();

  const loginClicked = async () =>
  {
    setOpen(false);
    let loginResult = (await login({ variables: { name: name.props.value, password: password.props.value } })).data!.login;
    if(loginResult.success)
    {
      name.setValue("");
      password.setValue("");
    }
    setSuccess(loginResult.success);
    setOpen(true);
    if(loginResult.success)
    {
      await saveToken({ variables: { token: loginResult.token! } });
      history.replace(props.returnurl || '/');
    }
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) =>
  {
    if(reason === 'clickaway')
    {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <Container maxWidth="xs" className={classes.root}>
        <Card>
          <CardContent>
            <Grid container direction="column" spacing={2}>
              <Grid item className={classes.logobox}>
                <img src={logo} alt="logo" className={classes.logo} />
                <div className={classes.welcome}>
                  Üdvözöl a {process.env.REACT_APP_NAME}!</div>
              </Grid>
              <Grid item>
                <TextField label="Felasználónév" required {...name.props} />
              </Grid>
              <Grid item>
                <TextField label="Jelszó" type="password" required {...password.props} />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button startIcon={loading ? <CircularProgress size={20} /> : <ExitToAppIcon />} variant="contained" disabled={!name.props.value || !password.props.value || loading} onClick={loginClicked}>Bejelentkezés</Button>
          </CardActions>
        </Card>
      </Container>
      <span className={classes.buildnumber}>
        <Tooltip title="Környezet"><span>{props.appConfig.env}: </span></Tooltip>
        <Tooltip title="Verziószám"><span>{process.env.REACT_APP_BUILDNUMBER} </span></Tooltip>
        <ServiceWorkerIcon state={props.serviceWorkerState} />
      </span>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={success ? "success" : "error"}>
          {success ? "A bejelentkezés sikerült!" : "A bejelentkezés nem sikerült!"}
        </Alert>
      </Snackbar>
    </>
  );
}
