import { Badge, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, GridList, GridListTile, IconButton, InputAdornment, List, ListItem, ListItemIcon, ListItemText, makeStyles, Tab, Tabs, TextField, Theme, useTheme } from "@material-ui/core";
import { red, yellow } from "@material-ui/core/colors";
import AddPhotoIcon from '@material-ui/icons/AddAPhotoOutlined';
import SizeIcon from '@material-ui/icons/AspectRatio';
import DeviationsIcon from '@material-ui/icons/AssignmentOutlined';
import PhotosIcon from '@material-ui/icons/CameraAltOutlined';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import WeightIcon from '@material-ui/icons/Speed';
import * as ImageConversion from 'image-conversion';
import React, { useEffect, useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { DeviationType, GetReceiptItem } from "../../graphql/generated/graphql";
import AddPhotoDialog from "./AddPhotoDialog";

const useStyles = makeStyles((theme: Theme) => ({
  scaled: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 230,
    marginTop: theme.spacing(2),
    "& input": {
      fontSize: 36,
    },
  },
  startAdornment: {
    "& p": {
      fontSize: 28,
      paddingTop: 7
    },
  },
  endAdornment: {
    "& p": {
      fontSize: 28,
      paddingTop: 25,
    }
  },
  swipableViews: {
    height: "calc(100vh - 189px)",
    "& > *": {
      height: "100%",
    },
  },
  errorBadge: {
    "& .MuiBadge-badge": {
      backgroundColor: red[600],
      color: theme.palette.getContrastText(red[600]),
    },
  },
  warningBadge: {
    "& .MuiBadge-badge": {
      backgroundColor: yellow[600],
      color: theme.palette.getContrastText(yellow[600]),
    },
  },
  deviationIcon: {
    minWidth: 36,
  },
  deviationText: {
    "& .MuiListItemText-primary": {
      fontSize: 18,
    }
  },
  dialogContent: {
    padding: 0
  },
  tabs: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  addPhotoIcon: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  },
  photosPanel: {
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    paddingTop: theme.spacing(0.5),
  }
}));

function a11yProps(index: any)
{
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

interface TabPanelProps
{
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
  nextValue: any;
  className?: string;
}

const TabPanel = (props: TabPanelProps) =>
{
  const { children, value, index, nextValue, ...other } = props;

  const visible = value === index || nextValue === index;

  return (
    <div
      role="tabpanel"
      hidden={!visible}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {visible ? children : null}
    </div>
  );
}

interface Inputs
{
  Weight: string;
  Length: string;
  Width: string;
  Height: string;
  CheckedDeviationTypeIds: number[];
  Photos: string[];
  Thumbnails: string[];
}

interface Validation
{
  WeightError: boolean;
  WeightErrors: number;
  WeightWarnings: number;
  LengthError: boolean;
  WidthError: boolean;
  HeightError: boolean;
  DimensionErrors: number;
  DimensionWarnings: number;
  DeviationWarnings: number;
  PhotoErrors: number;
  PhotoWarnings: number;
}

const TabIcon = (props: { errors: number, warnings: number, children: React.ReactNode }) =>
{
  const classes = useStyles();
  if(props.errors > 0) return <Badge badgeContent={props.errors} className={classes.errorBadge}>{props.children}</Badge>;
  if(props.warnings > 0) return <Badge badgeContent={props.warnings} className={classes.warningBadge}>{props.children}</Badge>;
  return <>{props.children}</>;
}

export default function Component(props: {
  Item: GetReceiptItem,
  DeviationTypes: DeviationType[],
  onClose: (Item: GetReceiptItem) => void,
})
{
  const classes = useStyles();
  const theme = useTheme();
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [nextTabIndex, setNextTabIndex] = useState(0);
  const [addPhotoIsOpen, setAddPhotoIsOpen] = useState(false);

  const handleTabChange = (event: React.ChangeEvent<{}>, index: number) =>
  {
    setNextTabIndex(currentTabIndex);
    setCurrentTabIndex(index);
  };

  const handleSwipeChange = (index: number) =>
  {
    setNextTabIndex(currentTabIndex);
    setCurrentTabIndex(index);
  };

  const onSwitching = (index: number) =>
  {
    if(index < currentTabIndex && nextTabIndex === currentTabIndex)
      setNextTabIndex(currentTabIndex - 1);
    if(index > currentTabIndex && nextTabIndex === currentTabIndex)
      setNextTabIndex(currentTabIndex + 1);
    if(index === currentTabIndex && nextTabIndex !== currentTabIndex)
      setNextTabIndex(currentTabIndex);
  };

  const handleTransitionEnd = () =>
  {
    setNextTabIndex(currentTabIndex);
  }

  const [inputs, setInputs] = useState<Inputs>({
    Weight: `${props.Item?.FactWeight ?? props.Item?.IndicatedWeight}`,
    Length: `${props.Item?.FactLength ?? props.Item?.IndicatedLength}`,
    Width: `${props.Item?.FactWidth ?? props.Item?.IndicatedWidth}`,
    Height: `${props.Item?.FactHeight ?? props.Item?.IndicatedHeight}`,
    CheckedDeviationTypeIds: props.Item ? [...props.Item?.Deviations!] : [],
    Photos: props.Item ? [...props.Item?.Photos!] : [],
    Thumbnails: [],
  });

  useEffect(() =>
  {
    (async (photos) =>
    {
      const thumbnails: string[] = [];
      for(const photo of photos)
      {
        const regex = /data:(?<mediatype>.*?)(;(?<encoding>.*))?,(?<data>.*)/gm;
        const matches = regex.exec(photo);
        if(matches)
        {
          const blob = await ImageConversion.dataURLtoFile(photo);
          const thumbnailBlob = await ImageConversion.compressAccurately(blob, { height: 160, accuracy: 0.6, size: 10, });
          const thumbnail = await ImageConversion.filetoDataURL(thumbnailBlob);
          thumbnails.push(thumbnail);
        }
        else
        {
          thumbnails.push(photo);
        }
      }
      const newInputs = { ...inputs, Thumbnails: thumbnails };
      setInputs(newInputs);
    })(props.Item.Photos);
    // eslint-disable-next-line
  }, [props.Item.Photos]);

  function validate(i: number | null | undefined, f: string | number | null | undefined)
  {
    if(typeof (f) === "string") f = parseInt(f);
    if(!((f ?? 0) > 0)) return { errors: 1, error: true, warnings: 0 };
    if(i && i !== f) return { errors: 0, error: false, warnings: 1 };
    return { errors: 0, error: false, warnings: 0 }
  }

  const getValidation = (defaults: boolean = false, inp: Inputs = inputs): Validation =>
  {
    const weight = validate(props.Item?.IndicatedWeight, defaults ? props.Item?.FactWeight : inp.Weight);
    const length = validate(props.Item?.IndicatedLength, defaults ? props.Item?.FactLength : inp.Length);
    const width = validate(props.Item?.IndicatedWidth, defaults ? props.Item?.FactWidth : inp.Width);
    const height = validate(props.Item?.IndicatedHeight, defaults ? props.Item?.FactHeight : inp.Height);
    return {
      WeightErrors: weight.errors,
      WeightError: weight.error,
      WeightWarnings: weight.warnings,
      LengthError: length.error,
      WidthError: width.error,
      HeightError: height.error,
      DimensionErrors: length.errors + width.errors + height.errors,
      DimensionWarnings: length.warnings + width.warnings + height.warnings,
      DeviationWarnings: inp.CheckedDeviationTypeIds.length,
      PhotoErrors: inp.CheckedDeviationTypeIds.length > 0 && inp.Photos.length === 0 ? 1 : 0,
      PhotoWarnings: inp.Photos.length,
    };
  }

  const [validation, setValidation] = useState<Validation>(getValidation(true));

  const handleInputChange = (prop: keyof Inputs) => (event: React.ChangeEvent<HTMLInputElement>) =>
  {
    const newInputs = { ...inputs, [prop]: event.target.value };
    const newValidation = getValidation(false, newInputs);

    const weightDeviationIndex = newInputs.CheckedDeviationTypeIds.indexOf(1);
    if(newValidation.WeightWarnings > 0 && weightDeviationIndex === -1)
    {
      newInputs.CheckedDeviationTypeIds.push(1);
    }
    else
    {
      if(newValidation.WeightWarnings === 0 && weightDeviationIndex !== -1)
      {
        newInputs.CheckedDeviationTypeIds.splice(weightDeviationIndex, 1);
      }
    }

    const dimensionDeviationIndex = newInputs.CheckedDeviationTypeIds.indexOf(2);
    if(newValidation.DimensionWarnings > 0 && dimensionDeviationIndex === -1)
    {
      newInputs.CheckedDeviationTypeIds.push(2);
    }
    else
    {
      if(newValidation.DimensionWarnings === 0 && dimensionDeviationIndex !== -1)
      {
        newInputs.CheckedDeviationTypeIds.splice(dimensionDeviationIndex, 1);
      }
    }

    setInputs(newInputs);
  };

  const addPhoto = (picture: Blob) =>
  {
    setAddPhotoIsOpen(false);
    (async (blob) =>
    {
      let thumbnailBlob: Blob | null = null;
      if(blob.type === 'image/png')
      {
        const pngDataUrl = await ImageConversion.filetoDataURL(blob);
        const jpegFile = await ImageConversion.dataURLtoFile(pngDataUrl, ImageConversion.EImageType.JPEG);
        blob = await ImageConversion.compress(jpegFile, 0.8);
        thumbnailBlob = await ImageConversion.compressAccurately(jpegFile, { height: 160, accuracy: 0.6, size: 10, });
      }
      if(!thumbnailBlob) thumbnailBlob = await ImageConversion.compressAccurately(blob, { height: 160, accuracy: 0.6, size: 10, });
      const photo = await ImageConversion.filetoDataURL(blob);
      const thumbnail = await ImageConversion.filetoDataURL(thumbnailBlob);
      const newInputs = { ...inputs, Photos: [...inputs.Photos, photo], Thumbnails: [...inputs.Thumbnails, thumbnail] };
      setInputs(newInputs);
    })(picture);
  };

  useEffect(() =>
  {
    setValidation(getValidation());
    // eslint-disable-next-line
  }, [inputs]);

  const toggleDeviationTypeId = (DeviationTypeId: number) => () =>
  {
    if(DeviationTypeId <= 2) return;
    const currentIndex = inputs.CheckedDeviationTypeIds.indexOf(DeviationTypeId);
    const newChecked = [...inputs.CheckedDeviationTypeIds];

    if(currentIndex === -1)
    {
      newChecked.push(DeviationTypeId);
    }
    else
    {
      newChecked.splice(currentIndex, 1);
    }
    setInputs({ ...inputs, CheckedDeviationTypeIds: newChecked });
  };

  const handleClose = () =>
  {
    const item = { ...props.Item! };
    item.FactWeight = parseInt(inputs.Weight);
    item.FactLength = parseInt(inputs.Length);
    item.FactWidth = parseInt(inputs.Width);
    item.FactHeight = parseInt(inputs.Height);
    item.Deviations = inputs.CheckedDeviationTypeIds;
    item.Photos = inputs.Photos;
    props.onClose(item);
  }

  return (
    <Dialog fullScreen open={props.Item != null}>
      <DialogTitle>{props.Item?.PackageNumber}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Tabs className={classes.tabs} variant="fullWidth" value={currentTabIndex} onChange={handleTabChange}>
          <Tab label="Súly" icon={<TabIcon errors={validation.WeightErrors} warnings={validation.WeightWarnings}> <WeightIcon /></TabIcon>} {...a11yProps(0)} />
          <Tab label="Méret" icon={<TabIcon errors={validation.DimensionErrors} warnings={validation.DimensionWarnings}><SizeIcon /></TabIcon>} {...a11yProps(1)} />
          <Tab label="Jegyzők." icon={<TabIcon errors={0} warnings={validation.DeviationWarnings}> <DeviationsIcon /></TabIcon>} {...a11yProps(2)} />
          <Tab label="Fotók" icon={<TabIcon errors={validation.PhotoErrors} warnings={validation.PhotoWarnings}> <PhotosIcon /></TabIcon>} {...a11yProps(3)} />
        </Tabs>
        <SwipeableViews id="sw" className={classes.swipableViews} index={currentTabIndex} onChangeIndex={handleSwipeChange} onSwitching={onSwitching} onTransitionEnd={handleTransitionEnd}>
          <TabPanel value={currentTabIndex} nextValue={nextTabIndex} index={0} dir={theme.direction}>
            <TextField value={inputs.Weight} onChange={handleInputChange('Weight')} className={classes.scaled} type="number" label="Súly" variant="filled"
              InputProps={{
                error: validation.WeightError,
                startAdornment: <InputAdornment position="start" className={classes.startAdornment}><p>{props.Item?.IndicatedWeight ?? '-'} / </p></InputAdornment>,
                endAdornment: <InputAdornment position="end" className={classes.endAdornment}>kg</InputAdornment>
              }}
            />
          </TabPanel>
          <TabPanel value={currentTabIndex} nextValue={nextTabIndex} index={1} dir={theme.direction}>
            <TextField value={inputs.Length} onChange={handleInputChange('Length')} className={classes.scaled} type="number" label="Hossz" defaultValue={props.Item?.FactLength ?? props.Item?.IndicatedLength} variant="filled"
              InputProps={{
                error: validation.LengthError,
                startAdornment: <InputAdornment position="start" className={classes.startAdornment}><p>{props.Item?.IndicatedLength ?? '-'} / </p></InputAdornment>,
                endAdornment: <InputAdornment position="end" className={classes.endAdornment}>cm</InputAdornment>
              }}
            />
            <TextField value={inputs.Width} onChange={handleInputChange('Width')} className={classes.scaled} type="number" label="Szélesség" defaultValue={props.Item?.FactWidth ?? props.Item?.IndicatedWidth} variant="filled"
              InputProps={{
                error: validation.WidthError,
                startAdornment: <InputAdornment position="start" className={classes.startAdornment}><p>{props.Item?.IndicatedWidth ?? '-'} / </p></InputAdornment>,
                endAdornment: <InputAdornment position="end" className={classes.endAdornment}>cm</InputAdornment>
              }}
            />
            <TextField value={inputs.Height} onChange={handleInputChange('Height')} className={classes.scaled} type="number" label="Magasság" defaultValue={props.Item?.FactHeight ?? props.Item?.IndicatedHeight} variant="filled"
              InputProps={{
                error: validation.HeightError,
                startAdornment: <InputAdornment position="start" className={classes.startAdornment}><p>{props.Item?.IndicatedHeight ?? '-'} / </p></InputAdornment>,
                endAdornment: <InputAdornment position="end" className={classes.endAdornment}>cm</InputAdornment>
              }}
            />
          </TabPanel>
          <TabPanel value={currentTabIndex} nextValue={nextTabIndex} index={2} dir={theme.direction}>
            <List>
              {props.DeviationTypes.map(dt => <ListItem disabled={dt.DeviationTypeId <= 2} key={dt.DeviationTypeId} dense onClick={toggleDeviationTypeId(dt.DeviationTypeId)}>
                <ListItemIcon className={classes.deviationIcon}>
                  <Checkbox checked={inputs.CheckedDeviationTypeIds.indexOf(dt.DeviationTypeId) !== -1} disableRipple />
                </ListItemIcon>
                <ListItemText className={classes.deviationText} id={`checkbox-list-label-${dt.DeviationTypeId}`} primary={dt.Name} />
              </ListItem>)}
            </List>
          </TabPanel>
          <TabPanel value={currentTabIndex} nextValue={nextTabIndex} index={3} dir={theme.direction} className={classes.photosPanel}>
            <GridList cellHeight={160} cols={3}>
              {inputs.Thumbnails.map((p, i) => <GridListTile key={i} >
                <img src={p} decoding="async" loading="lazy" alt="Fotó" />
              </GridListTile>)}
              <GridListTile key={0}>
                <IconButton className={classes.addPhotoIcon} onClick={() => setAddPhotoIsOpen(true)}>
                  <AddPhotoIcon fontSize="large" />
                </IconButton>
              </GridListTile>
            </GridList>
          </TabPanel>
        </SwipeableViews>
        <AddPhotoDialog dialogOpen={addPhotoIsOpen} onClose={(photo) =>
        {
          addPhoto(photo);
        }} />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" disabled={validation.WeightErrors > 0 || validation.DimensionErrors > 0 || validation.PhotoErrors > 0} startIcon={<SaveOutlinedIcon />} onClick={handleClose}>Átvéve</Button>
      </DialogActions>
    </Dialog>
  );
}
